/**
 * @author      Abdul Pathan
 * @date        Aug, 2024
 * @copyright   www.ibirdsservices.com
 */

import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap'
import WhatsAppAPI from '../../api/WhatsAppAPI';
import { ToastContainer, toast } from 'react-toastify'; // npm i react-toastify --force
import 'react-toastify/dist/ReactToastify.css';
// import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
// const moment = require('moment-timezone');

const UtilityTemplate = ({ previewData }) => {
    const initialFormData = { template_id: '', template_name: '', language: '', header: '', header_text: '', message_body: '', footer: '' };
    const navigate = useNavigate();
    const [isSending, setIsSending] = useState(false);
    const [isSpinner, setIsSpinner] = useState(false);
    // const [selectedFile, setSelectedFile] = useState(null);
    // const [errorMessage, setErrorMessage] = useState('');
    const [rowData, setRowData] = useState(initialFormData);

    console.log('UTILITY Template previewData', previewData)

    useEffect(() => {
        if (previewData && previewData?.category === 'UTILITY') {
            setRowData({
                template_id: previewData?.template_id || '',
                template_name: previewData?.templatename || '',
                language: previewData?.language || '',
                header: previewData?.header || '',
                header_text: previewData?.header_text || '',
                message_body: previewData?.message || '',
                footer: previewData?.footer || '',
            });
        } else {
            setRowData(initialFormData);
        }
    }, [previewData]);


    const handleChange = (event) => {
        const { name, value } = event.target;
        // setRowData({ ...rowData, [name]: value });
        setRowData(prevData => ({ ...prevData, [name]: value }));
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log('row Data, ', rowData)

        if (rowData.header === 'text' && !rowData.header_text.trim()) {
            toast.error('Template header text is required.');
            return;
        }

        const formattedName = rowData.template_name.toLowerCase().replace(/[^a-z0-9]+/g, '_').replace(/^_+|_+$/g, '');

        const reqbody = {
            name: formattedName,
            language: rowData.language,
            category: 'UTILITY',
            components: [
                {
                    type: 'HEADER',
                    format: 'TEXT',
                    text: rowData?.header_text || '',
                },
                {
                    type: 'BODY',
                    text: rowData?.message_body,
                },
                {
                    type: 'FOOTER',
                    text: rowData?.footer || '',
                },
            ],
        };


        try {
            setIsSpinner(true)
            let result;
            if (rowData?.template_id) {
                result = await WhatsAppAPI.updateTemplate(rowData.template_id, reqbody);
            } else {
                result = await WhatsAppAPI.createMarketingAndUtilityTemplate(reqbody);
            }

            console.log('###result', result)

            if (result.error) {
                setIsSpinner(false)
                toast.error(`${result.error.error_user_title} ${result.error.error_user_msg}`);
            } else {
                toast.success(rowData?.id ? 'Template updated successfully.' : 'Template created successfully.');
                setRowData(initialFormData);
                setIsSending(false);
                navigate("/whatsapp_template");
            }
        } catch (error) {
            toast.error('An unexpected error occurred.');
        } finally {
            setIsSending(false);
        }
    };


    // clear
    const handleClear = () => {
        setRowData(initialFormData);
        setIsSending(false);
    }

    const handleBack = () => {
        navigate("/whatsapp_template");
    }

    const isFormValid = Boolean(rowData.template_name) && Boolean(rowData.language) && Boolean(rowData.message_body);


    return (
        <>
            {!isSpinner ? <>

                <Container className='mt-1'>
                    <Row className='mx-5'>
                        <Col lg={12} sm={12} xs={12}>
                            <Card className='h-100' style={{ border: "none" }}>
                                <Card.Body>
                                    <Row>
                                        <Col lg={12} sm={12} xs={12}>
                                            <Form.Group className='mx-2 mb-3' controlId="formBasicName">
                                                <Form.Label className="form-view-label" htmlFor="formBasicName">
                                                    <b>Template name and language</b>
                                                </Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mb-3'>
                                        <Col lg={6} sm={12} xs={12}>
                                            <Form.Group className='mx-2 mb-3' controlId="formBasicTemplateName">
                                                <Form.Label className="form-view-label" htmlFor="formBasicTemplateName">
                                                    Template Name
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="template_name"
                                                    value={rowData?.template_name}
                                                    onChange={handleChange}
                                                    placeholder="Enter template name"
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col lg={6} sm={12} xs={12}>
                                            <Form.Group className='mb-2 mx-3'>
                                                <Form.Label className="form-view-label" htmlFor="formBasicLanguage">
                                                    Language
                                                </Form.Label>
                                                <Form.Select
                                                    aria-label="select language"
                                                    name="language"
                                                    value={rowData?.language}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value="">Select Language</option>
                                                    <option value="en">English</option>
                                                    <option value="en_US">English (US)</option>
                                                    <option value="en_GB">English (UK)</option>
                                                    <option value="hi">Hindi</option>
                                                    <option value="ur">Urdu</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row >
                </Container >

                <Container className='mt-1'>
                    <Row className='mx-5'>
                        <Col lg={12} sm={12} xs={12} className="mb-2">
                            <Card className='h-100' style={{ border: "none" }}>
                                <Card.Body>
                                    <Row>
                                        <Col lg={12} sm={12} xs={12}>
                                            <Form.Group className='mx-2 mb-3' >
                                                <Form.Label className="form-view-label" htmlFor="formBasicContent">
                                                    <b>Content </b> ( Fill in the header, body and footer sections of your template. )
                                                </Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mb-3'>
                                        <Col lg={6} sm={12} xs={12}>
                                            <Form.Group className='mb-3 mx-3'>
                                                <Form.Label className="form-view-label" htmlFor="formBasicHeader">
                                                    Header
                                                </Form.Label>
                                                <Form.Select
                                                    aria-label="select language"
                                                    name="header"
                                                    value={rowData?.header}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">Select Header</option>
                                                    <option value="text">Text</option>
                                                    <option value="image">Image</option>
                                                    <option value="video">Video</option>
                                                    <option value="document">Document</option>
                                                    <option value="location">Location</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>

                                        {rowData.header === 'text' &&
                                            <Col lg={6} sm={12} xs={12}>
                                                <Form.Group className='mx-2 mb-3' controlId="formBasicHeaderText">
                                                    <Form.Label className="form-view-label" htmlFor="formBasicHeaderText">
                                                        Header Text
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="header_text"
                                                        value={rowData?.header_text}
                                                        onChange={handleChange}
                                                        placeholder="Enter text here..."
                                                        required
                                                    />
                                                </Form.Group>
                                            </Col>
                                        }

                                        {rowData.header === 'image' &&
                                            <Col lg={6} sm={12} xs={12}>
                                                <Form.Group className='mb-3 mx-3' controlId="formFile">
                                                    <Form.Label >Upload File</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        accept=".xls, .xlsx" // Only allow .xls and .xlsx files
                                                    // onChange={handleFileChange}
                                                    />
                                                    {/* {errorMessage && <Form.Text className="text-danger">{errorMessage}</Form.Text>} */}
                                                </Form.Group>
                                            </Col>
                                        }
                                    </Row>

                                    <Row className='mb-3'>
                                        <Col lg={12} sm={12} xs={12}>
                                            <Form.Group className='mx-3 mb-3' controlId="formBasicBody">
                                                <Form.Label className="form-view-label" htmlFor="formBasicBody">
                                                    Body
                                                </Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    name="message_body"
                                                    value={rowData?.message_body}
                                                    onChange={handleChange}
                                                    placeholder="type text here..."
                                                    required
                                                    rows={3}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col lg={6} sm={12} xs={12}>
                                            <Form.Group className='mx-3 mb-3' controlId="formBasicFooter">
                                                <Form.Label className="form-view-label" htmlFor="formBasicFooter">
                                                    Footer
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="footer"
                                                    value={rowData?.footer}
                                                    onChange={handleChange}
                                                    placeholder="type text here..."
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>


                                    <Row className='mt-2'>
                                        <Col lg={12} sm={12} xs={12}>
                                            <hr></hr>
                                        </Col>
                                    </Row>

                                    <Row className='g-0 mb-2'>
                                        <Col lg={12} sm={12} xs={12} className="text-end mt-2">
                                            <Button className='mx-2' variant="light" onClick={handleBack} disabled={isSending}>
                                                Back
                                            </Button>
                                            <Button className='mx-2' variant="light" onClick={handleClear} disabled={isSending}>
                                                Clear
                                            </Button>
                                            <Button variant="outline-secondary" disabled={!isFormValid || isSending} onClick={handleSubmit}>
                                                {isSending ? 'Submitting...' : 'Submit for Review'}
                                            </Button>
                                        </Col>
                                    </Row>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row >
                </Container >

            </>
                :
                <Container className='mt-1'>
                    <Row className='mx-5'>
                        <Col lg={12} sm={12} xs={12}>
                            <Card className='h-100' style={{ border: "none" }}>
                                <Card.Body>
                                    <Row className='mb-3'>
                                        <Col lg={12} sm={12} xs={12}>
                                            <div className="sk-cube-grid">
                                                <div className="sk-cube sk-cube1"></div>
                                                <div className="sk-cube sk-cube2"></div>
                                                <div className="sk-cube sk-cube3"></div>
                                                <div className="sk-cube sk-cube4"></div>
                                                <div className="sk-cube sk-cube5"></div>
                                                <div className="sk-cube sk-cube6"></div>
                                                <div className="sk-cube sk-cube7"></div>
                                                <div className="sk-cube sk-cube8"></div>
                                                <div className="sk-cube sk-cube9"></div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row >
                </Container >
            }

            <ToastContainer />
        </>
    )
}

export default UtilityTemplate;
