/**
 * @author      Abdul Pathan
 * @date        Aug, 2024
 * @copyright   www.ibirdsservices.com
 */

import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';//npm i react-toastify --force
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import WhatsAppAPI from '../../api/WhatsAppAPI';

const AuthenticationTemplate = ({ previewData }) => {
    const navigate = useNavigate();
    const initialFormData = { template_id: '', template_name: '', language: '', header: '', header_text: '', message_body: '', footer: '' };

    const [rowData, setRowData] = useState(initialFormData);
    const [isSending, setIsSending] = useState(false);

    useEffect(() => {
        if (previewData && previewData?.category === 'AUTHENTICATION') {
            setRowData({
                template_id: previewData?.template_id || '',
                template_name: previewData?.templatename || '',
                language: previewData?.language || '',
                header: previewData?.header || '',
                header_text: previewData?.header_text || '',
                message_body: previewData?.message || '',
                footer: previewData?.footer || '',
            });
        } else {
            setRowData(initialFormData);
        }
    }, [previewData]);


    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setRowData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const codeExpiration = parseInt(rowData.code_expiration_minutes, 10);

        if (!codeExpiration || codeExpiration < 1 || codeExpiration > 90) {
            toast.error('Code expiration must be between 1 and 90 minutes');
            return;
        }

        if (!rowData.name || !rowData.language || !rowData.code_expiration_minutes || !rowData.add_security_recommendation) {
            toast.error('Please fill all required fields');
            return;
        }

        setIsSending(true); // Set isSending to true when starting the submission

        const formattedName = rowData.name.toLowerCase().replace(/[^a-z0-9]+/g, '_').replace(/^_+|_+$/g, '');

        const reqBody = {
            name: formattedName,
            languages: rowData.language,
            category: 'AUTHENTICATION',
            components: [
                {
                    type: 'BODY',
                    add_security_recommendation: rowData.add_security_recommendation
                },
                {
                    type: 'FOOTER',
                    code_expiration_minutes: rowData.code_expiration_minutes
                },
                {
                    type: 'BUTTONS',
                    buttons: [
                        {
                            type: 'OTP',
                            otp_type: 'COPY_CODE'
                        }
                    ]
                }
            ]
        };

        try {
            let result;
            if (rowData?.id) {
                // Update existing template
                result = await WhatsAppAPI.updateTemplate(rowData.id, reqBody);
            } else {
                // Create new template
                result = await WhatsAppAPI.createAuthenticationTemplate(reqBody);
            }

            if (result.error) {
                toast.error(`${result.error.error_user_title} ${result.error.error_user_msg}`);
            } else {
                toast.success(rowData?.id ? 'Template updated successfully.' : 'Template created successfully.');
                navigate('/whatsapp_template');
                setRowData(initialFormData); // Reset form data to initial values after successful creation
            }
        } catch (error) {
            toast.error('An unexpected error occurred.');
        } finally {
            setIsSending(false); // Set isSending to false when the submission is complete
        }
    };

    const isFormValid = rowData.name && rowData.language && rowData?.code_expiration_minutes && rowData.add_security_recommendation;

    const handleBack = () => {
        navigate(`/whatsapp_template`);
    }

    return (
        <>
            <Container className='mt-1'>
                <Row className='mx-5'>
                    <Col lg={12} sm={12} xs={12}>
                        <Card className='h-100' style={{ border: "none" }}>
                            <Card.Body>
                                <Row>
                                    <Col lg={12} sm={12} xs={12}>
                                        <Form.Group className='mx-2 mb-3' controlId="formBasicDescription">
                                            <Form.Label className="form-view-label" htmlFor="formBasicDescription">
                                                <b>Template name and language</b>
                                            </Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className='mb-3'>
                                    <Col lg={6} sm={12} xs={12}>
                                        <Form.Group className='mx-3 mb-3' controlId="formBasicName">
                                            <Form.Label className="form-view-label" htmlFor="formBasicName">
                                                Template Name
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="template_name"
                                                value={rowData?.template_name}
                                                onChange={handleChange}
                                                placeholder="Enter template name"
                                                required
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6} sm={12} xs={12}>
                                        <Form.Group className='mb-2 mx-3'>
                                            <Form.Label className="form-view-label" htmlFor="formBasicLanguage">
                                                Language
                                            </Form.Label>
                                            <Form.Select
                                                aria-label="select language"
                                                name="language"
                                                value={rowData?.language}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="">Select Language</option>
                                                <option value="en">English</option>
                                                <option value="en_US">English (US)</option>
                                                <option value="en_GB">English (UK)</option>
                                                <option value="hi">Hindi</option>
                                                <option value="ur">Urdu</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className='g-0'>
                                    <Col lg={6} sm={12} xs={12}>
                                        <Form.Group className='mx-3 mb-3' controlId="formBasicCodeExpiration">
                                            <Form.Label className="form-view-label" htmlFor="formBasicCodeExpiration">
                                                Code Expiration Minutes <i className="fa-solid fa-circle-info" title='Enter a value between 1 and 90.'></i>
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="code_expiration_minutes"
                                                value={rowData.code_expiration_minutes}
                                                onChange={handleChange}
                                                placeholder="Enter code expiration in minutes"
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6} sm={12} xs={12} className='mt-4 pt-3'>
                                        <Form.Group className='mx-3 mb-3' controlId="formBasicSecurityRecommendation">
                                            <Form.Check
                                                name='add_security_recommendation'
                                                type="checkbox"
                                                checked={rowData.add_security_recommendation}
                                                onChange={handleChange}
                                                label="Add Security Recommendation"
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className='mt-2'>
                                    <Col lg={12} sm={12} xs={12}>
                                        <hr></hr>
                                    </Col>
                                </Row>

                                <Row className='g-0 mb-2'>
                                    <Col lg={12} sm={12} xs={12} className="text-end mt-2">
                                        <Button className='mx-2' variant="light" onClick={handleBack} disabled={isSending}>
                                            Back
                                        </Button>
                                        <Button className='mx-2' variant="light" disabled={isSending}>
                                            Clear
                                        </Button>
                                        <Button variant="outline-secondary" disabled={!isFormValid || isSending} onClick={handleSubmit}>
                                            {isSending ? 'Submitting...' : 'Submit for Review'}
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row >
            </Container >


            <ToastContainer />
        </>
    );
}

export default AuthenticationTemplate;
