/**
 * @author      Abdul Pathan
 * @date        Aug, 2024
 * @copyright   www.ibirdsservices.com
 */

import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Col, Container, Form, Image, Row } from 'react-bootstrap'
import WhatsAppAPI from '../../api/WhatsAppAPI';
import { ToastContainer, toast } from 'react-toastify'; // npm i react-toastify --force
import 'react-toastify/dist/ReactToastify.css';
// import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
// const moment = require('moment-timezone');

const MarketingTemplate = ({ previewData }) => {
    const initialFormData = {
        template_id: '',
        template_name: '',
        language: '',
        status: '',
        category: '',
        header: '',
        header_text: '',
        header_image_url: '',
        header_document_url: '',
        message_body: '',
        footer: '',
    };


    const navigate = useNavigate();
    const [isSending, setIsSending] = useState(false);
    const [isSpinner, setIsSpinner] = useState(false);
    // const [selectedFile, setSelectedFile] = useState(null);
    // const [errorMessage, setErrorMessage] = useState('');
    const [rowData, setRowData] = useState(initialFormData);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState(null);
    // const [imageShowHide, setImageShowHide] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    console.log('MarketingTemplate previewData', previewData)

    useEffect(() => {
        if (previewData && previewData?.category === 'MARKETING') {
            setRowData({
                template_id: previewData?.template_id || '',
                template_name: previewData?.templatename || '',
                language: previewData?.language || '',
                status: previewData?.status || '',
                category: previewData?.category || '',
                header: previewData?.header || '',
                header_text: previewData?.header_text || '',
                header_image_url: previewData?.header_image_url || '',
                header_document_url: previewData?.header_document_url || '',
                message_body: previewData?.message_body || '',
                footer: previewData?.footer || '',
            });

            // setSelectedImage(previewData?.header_image_url ? { name: previewData?.header_image_url.split('/').pop() } : null);
            // setSelectedDocument(previewData?.header_document_url ? { name: previewData?.header_document_url.split('/').pop() } : null);
            // setImageShowHide(previewData?.header_image_url ? (previewData?.header_image_url) : null)

            setSelectedImage(previewData?.header_image_url ? { name: 'Image' } : null);
            setSelectedDocument(previewData?.header_document_url ? { name: 'PDF' } : null);


        } else {
            setRowData(initialFormData);
            // setImageShowHide(null)
            setSelectedImage(null);
            setSelectedDocument(null);
        }
    }, [previewData]);


    const handleChange = (event) => {
        const { name, value } = event.target;
        setRowData(prevData => ({ ...prevData, [name]: value }));
    }

    const handleFileChange = (event) => {
        const { name, files } = event.target;

        const file = files[0];

        if (!file) return;


        const fileExtension = file.name.split('.').pop().toLowerCase();
        let validExtensions;

        switch (name) {
            case 'header_image':
                validExtensions = ['jpg', 'jpeg', 'png'];
                break;
            case 'header_document':
                validExtensions = ['pdf'];
                break;
            case 'header_video':
                validExtensions = ['mp4'];
                break;
            default:
                validExtensions = [];
                break;
        }

        if (!validExtensions.includes(fileExtension)) {
            setErrorMessage(`Only ${validExtensions.join(', ')} files are allowed.`);
            if (name === 'header_image') setSelectedImage(null);
            if (name === 'header_document') setSelectedDocument(null);
            event.target.value = ''; // Reset file input value
            return;
        }

        setErrorMessage('');

        if (name === 'header_image') {
            setSelectedImage(file);//URL.createObjectURL(file)
            console.log('####setSelectedImage file', file);
        } else if (name === 'header_document') {
            setSelectedDocument(file);
        } else if (name === 'header_video') {
            setSelectedVideo(file);
        }
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log('row Data, ', rowData)

        console.log('setSelectedImage@##', selectedImage);

        const obj = {
            name: selectedImage.name,
            size: selectedImage.size,
            type: selectedImage.type
        }
        console.log('obj', obj)


        const formData = new FormData();
        formData.append("messaging_product", "whatsapp");
        formData.append("file", selectedImage);
        // formData.append("description", 'Outgoing');


        const uploadSessionId = await WhatsAppAPI.uploadDocumentWithApi2(formData);
        console.log('uploadSessionId ', uploadSessionId)

        if (uploadSessionId) {
            const document = await WhatsAppAPI.uploadDocumentWithApi3(uploadSessionId, URL.createObjectURL(selectedImage));//URL.createObjectURL(selectedImage)
            console.log('####document', document)
        }




        if (rowData.header === 'TEXT' && !rowData.header_text.trim()) {
            toast.error('Template header text is required.');
            return;
        }

        if (rowData.header === 'IMAGE' && !selectedImage) {
            toast.error('Upload image is required.');
            return;
        }
        if (rowData.header === 'DOCUMENT' && !selectedDocument) {
            toast.error('Upload document is required.');
            return;
        }
        if (rowData.header === 'VIDEO' && !selectedVideo) {
            toast.error('Upload video mp4 is required.');
            return;
        }

        // console.log('selectedImage : ', URL.createObjectURL(selectedImage));

        const formattedName = rowData.template_name.toLowerCase().replace(/[^a-z0-9]+/g, '_').replace(/^_+|_+$/g, '');

        const reqBody = {
            name: formattedName,
            language: rowData.language,
            category: 'MARKETING',
            components: [
                {
                    type: 'HEADER',
                    format: rowData.header,
                    text: rowData?.header_text || '',
                    ...(rowData.header === 'TEXT' && { text: rowData.header_text }),
                    ...(rowData.header === 'IMAGE' && { example: { header_handle: [URL.createObjectURL(selectedImage)] } }),
                    ...(rowData.header === 'DOCUMENT' && { example: { header_handle: [URL.createObjectURL(selectedDocument)] } }),
                    ...(rowData.header === 'VIDEO' && { example: { header_handle: [URL.createObjectURL(selectedVideo)] } }),
                },
                {
                    type: 'BODY',
                    text: rowData?.message_body,
                },
                {
                    type: 'FOOTER',
                    text: rowData?.footer || '',
                },
            ],
        };

        console.log('##### reqBody :', reqBody)


        // try {
        //     setIsSpinner(true)
        //     let result;
        //     if (rowData?.template_id) {
        //         result = await WhatsAppAPI.updateTemplate(rowData.template_id, reqBody);
        //     } else {
        //         result = await WhatsAppAPI.createMarketingAndUtilityTemplate(reqBody);
        //     }

        //     console.log('###result', result)

        //     if (result?.error) {
        //         toast.error(`${result.error.type} ${result.error.message}`);
        //     } else {
        //         toast.success(rowData?.id ? 'Template updated successfully.' : 'Template created successfully.');
        //         // setRowData(initialFormData);
        //         setIsSpinner(false);
        //         navigate('/whatsapp_template');
        //     }
        // } catch (error) {
        //     toast.error('An unexpected error occurred.');
        // } finally {
        //     setIsSending(false);
        //     setIsSpinner(false)
        // }
    };


    // clear
    // const handleClear = () => {
    //     setRowData(initialFormData);
    //     setIsSending(false);
    // }

    const handleBack = () => {
        navigate("/whatsapp_template");
    }

    const isFormValid = Boolean(rowData.template_name.trim()) && Boolean(rowData.language) && Boolean(rowData.message_body.trim());


    return (
        <>
            {!isSpinner ? <>

                <Container className='mt-1'>
                    <Row className='mx-5'>
                        <Col lg={12} sm={12} xs={12}>
                            <Card className='h-100' style={{ border: "none" }}>
                                <Card.Body>
                                    <Row>
                                        <Col lg={12} sm={12} xs={12}>
                                            <Form.Group className='mx-2 mb-3' controlId="formBasicName">
                                                <Form.Label className="form-view-label" htmlFor="formBasicName">
                                                    <b>Template name and language</b>
                                                </Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mb-3'>
                                        <Col lg={6} sm={12} xs={12}>
                                            <Form.Group className='mx-2 mb-3' controlId="formBasicTemplateName">
                                                <Form.Label className="form-view-label" htmlFor="formBasicTemplateName">
                                                    Template Name
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="template_name"
                                                    value={rowData?.template_name}
                                                    onChange={handleChange}
                                                    placeholder="Enter template name"
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col lg={6} sm={12} xs={12}>
                                            <Form.Group className='mb-2 mx-3'>
                                                <Form.Label className="form-view-label" htmlFor="formBasicLanguage">
                                                    Language
                                                </Form.Label>
                                                <Form.Select
                                                    aria-label="select language"
                                                    name="language"
                                                    value={rowData?.language}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value="">Select Language</option>
                                                    <option value="en">English</option>
                                                    <option value="en_US">English (US)</option>
                                                    <option value="en_GB">English (UK)</option>
                                                    <option value="hi">Hindi</option>
                                                    <option value="ur">Urdu</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row >
                </Container >

                <Container className='mt-1'>
                    <Row className='mx-5'>
                        <Col lg={12} sm={12} xs={12} className="mb-2">
                            <Card className='h-100' style={{ border: "none" }}>
                                <Card.Body>
                                    <Row>
                                        <Col lg={12} sm={12} xs={12}>
                                            <Form.Group className='mx-2 mb-3' >
                                                <Form.Label className="form-view-label" htmlFor="formBasicContent">
                                                    <b>Content </b> ( Fill in the header, body and footer sections of your template. )
                                                </Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mb-3'>
                                        <Col lg={6} sm={12} xs={12}>
                                            <Form.Group className='mb-3 mx-3'>
                                                <Form.Label className="form-view-label" htmlFor="formBasicHeader">
                                                    Header
                                                </Form.Label>
                                                <Form.Select
                                                    aria-label="select language"
                                                    name="header"
                                                    value={rowData?.header}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">Select Header</option>
                                                    <option value="TEXT">Text</option>
                                                    <option value="IMAGE">Image</option>
                                                    <option value="DOCUMENT">Document</option>
                                                    <option value="VIDEO">Video</option>
                                                    {/* <option value="location">Location</option> */}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>

                                        {rowData.header === 'TEXT' &&
                                            <Col lg={6} sm={12} xs={12}>
                                                <Form.Group className='mx-2 mb-3' controlId="formBasicHeaderText">
                                                    <Form.Label className="form-view-label" htmlFor="formBasicHeaderText">
                                                        Header Text
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="header_text"
                                                        value={rowData?.header_text}
                                                        onChange={handleChange}
                                                        placeholder="Enter text here..."
                                                        required
                                                    />
                                                </Form.Group>
                                            </Col>
                                        }
                                    </Row>

                                    <Row className='mb-3'>
                                        <Col lg={6} sm={12} xs={12}>
                                            {rowData.header === 'IMAGE' &&
                                                <Form.Group className='mb-3 mx-3' controlId="formFileImage">
                                                    <Form.Label>Upload Image</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="file"
                                                        name="header_image"
                                                        accept="image/*" // Allow only image files
                                                        onChange={handleFileChange}
                                                    />
                                                    {selectedImage && <Form.Text className="text-muted">Selected File: {selectedImage.name}</Form.Text>}
                                                    {errorMessage && <Form.Text className="text-danger">{errorMessage}</Form.Text>}
                                                </Form.Group>
                                                // {imageShowHide &&
                                                //     <Image className='mx-3' variant="top" src={imageShowHide} thumbnail style={{ width: "15%" }} >
                                                //     </Image>
                                                // } 
                                            }

                                            {rowData.header === 'DOCUMENT' &&
                                                <Form.Group className='mb-3 mx-3' controlId="formFileDocument">
                                                    <Form.Label>Upload Document</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="file"
                                                        name="header_document"
                                                        accept=".pdf" // Allow only PDF files
                                                        onChange={handleFileChange}
                                                    />
                                                    {selectedDocument && <Form.Text className="text-muted">Selected File: {selectedDocument.name}</Form.Text>}
                                                    {errorMessage && <Form.Text className="text-danger">{errorMessage}</Form.Text>}
                                                </Form.Group>
                                            }

                                            {rowData.header === 'VIDEO' &&
                                                <Form.Group className='mb-3 mx-3' controlId="formFileVideo">
                                                    <Form.Label>Upload Video</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="file"
                                                        name="header_video"
                                                        accept="video/mp4" // Allow only MP4 videos
                                                        onChange={handleFileChange}
                                                    />
                                                    {selectedVideo && <Form.Text className="text-muted">Selected File: {selectedVideo.name}</Form.Text>}
                                                    {errorMessage && <Form.Text className="text-danger">{errorMessage}</Form.Text>}
                                                </Form.Group>
                                            }
                                        </Col>
                                    </Row>

                                    <Row className='mb-3'>
                                        <Col lg={12} sm={12} xs={12}>
                                            <Form.Group className='mx-3 mb-3' controlId="formBasicBody">
                                                <Form.Label className="form-view-label" htmlFor="formBasicBody">
                                                    Body
                                                </Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    name="message_body"
                                                    value={rowData?.message_body}
                                                    onChange={handleChange}
                                                    placeholder="type text here..."
                                                    required
                                                    rows={3}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col lg={6} sm={12} xs={12}>
                                            <Form.Group className='mx-3 mb-3' controlId="formBasicFooter">
                                                <Form.Label className="form-view-label" htmlFor="formBasicFooter">
                                                    Footer
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="footer"
                                                    value={rowData?.footer}
                                                    onChange={handleChange}
                                                    placeholder="type text here..."
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>


                                    <Row className='mt-2'>
                                        <Col lg={12} sm={12} xs={12}>
                                            <hr></hr>
                                        </Col>
                                    </Row>

                                    <Row className='g-0 mb-2'>
                                        <Col lg={12} sm={12} xs={12} className="text-end mt-2">
                                            <Button className='mx-2' variant="light" onClick={handleBack} disabled={isSending}>
                                                Back
                                            </Button>
                                            {/* <Button className='mx-2' variant="light" onClick={handleClear} disabled={isSending}>
                                                Clear
                                            </Button> */}
                                            <Button variant="outline-secondary" disabled={!isFormValid || isSending} onClick={handleSubmit}>
                                                {isSending ? 'Submitting...' : 'Submit for Review'}
                                            </Button>
                                        </Col>
                                    </Row>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row >
                </Container >

            </>
                :
                <Container className='mt-1'>
                    <Row className='mx-5'>
                        <Col lg={12} sm={12} xs={12}>
                            <Card className='h-100' style={{ border: "none" }}>
                                <Card.Body>
                                    <Row className='mb-3'>
                                        <Col lg={12} sm={12} xs={12}>
                                            <div className="sk-cube-grid">
                                                <div className="sk-cube sk-cube1"></div>
                                                <div className="sk-cube sk-cube2"></div>
                                                <div className="sk-cube sk-cube3"></div>
                                                <div className="sk-cube sk-cube4"></div>
                                                <div className="sk-cube sk-cube5"></div>
                                                <div className="sk-cube sk-cube6"></div>
                                                <div className="sk-cube sk-cube7"></div>
                                                <div className="sk-cube sk-cube8"></div>
                                                <div className="sk-cube sk-cube9"></div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row >
                </Container >
            }

            <ToastContainer />
        </>
    )
}

export default MarketingTemplate;
