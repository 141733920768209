import "./App.css";
import "./Sidebar.css"
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import Home from "./components/Home";
import React, { useEffect, useState } from "react";
import Alert from 'react-bootstrap/Alert';
import MiniSidebar from "./components/MiniSidebar";
import * as constants from './constants/CONSTANT';

import PubSub from 'pubsub-js';
import { Toast, ToastContainer } from "react-bootstrap";
import UserList from "./components/UserList";
import UserView from "./components/UserView";
import jwt_decode from "jwt-decode";
import io from "socket.io-client";
import Main from "./components/layout/Main";
import WhatsAppMessenger from "./components/whatsapp_messenger/WhatsAppMessenger";
import Templates from "./components/whatsapp_template/Templates";
import Campaign from "./components/campaign/Campaign";
import CampaignView from "./components/campaign/CampaignView";
import WhatsAppSetting from "./components/whatsapp_setting/WhatsAppSetting";
import CampaignAdd from "./components/campaign/CampaignAdd";
import TemplateAdd from "./components/whatsapp_template/TemplateAdd";

import Contacts from "./components/contacts/Contacts";
import ContactAdd from "./components/contacts/ContactAdd";

// Added by shivani start
import LeadList from "./components/leads/LeadList";
import LeadView from "./components/leads/LeadView";
import LeadEdit from "./components/leads/LeadEdit";
import { ContactView } from "./components/contacts";

// Added by shivani end

const ENDPOINT = 'https://api.indicrm.io/' || 'http://localhost:3003';
// const ENDPOINT = 'https://api.indicrm.io/';

function App() {
  const [modalShow, setModalShow] = useState(false);
  const [title, setTitle] = useState('Confirmation');
  const [message, setMessage] = useState('');
  const [variant, setVariant] = useState('');
  const [userInfo, setUserInfo] = useState({});
  const [permissions, setPermissions] = useState();
  const [connectedSocket, setConnectedSocket] = useState();

  const mySubscriber = (msg, data) => {
    switch (msg) {
      case 'RECORD_SAVED_TOAST':
        setTitle(data.title);
        setMessage(data.message);
        setModalShow(true);
        setVariant('success')
        //console.log('mytopic: RECORD_SAVED_TOAST'); // add your desired log message
        break;
      case 'RECORD_ERROR_TOAST':
        setTitle(data.title);
        setMessage(data.message);
        setModalShow(true);
        setVariant('danger')
        //console.log('mytopic: RECORD_ERROR_TOAST'); // add your desired log message
        break;
      default:
        //console.log('mytopic: default'); // add your desired log message
        break;
    }
  };

  useEffect(() => {
    console.log("Initializing Socket.io...");
    try {
      const token = localStorage.getItem('token');
      if (token) {
        let user = jwt_decode(token);
        setUserInfo(user);
        const perm = user.permissions.map((obj) => obj.name).join(';');
        setPermissions(perm);

        let socket = io(ENDPOINT, {
          path: '/ibs/socket.io',
          transports: ['websocket'],
          reconnection: true,
          // reconnectionAttempts: 5,
          // reconnectionDelay: 1000,
        });

        socket.on("connect", () => {
          console.log('Socket connected:', socket.id);
          socket.emit("setup", user);
          setConnectedSocket(socket);
        });

        socket.on("connected", () => {
          console.log('Socket setup complete');
        });

        socket.on("receivedwhatsappmessage", (message) => {
          console.log('#Received WhatsApp message:', message);
          console.log('##socket', socket)
        });

        socket.on("disconnect", (reason) => {
          console.log('Socket disconnected:', reason);
        });

        socket.on("connect_error", (err) => {
          console.error('Connection error:', err);
        });

        setConnectedSocket(socket);

        return () => {
          if (connectedSocket) {
            connectedSocket.disconnect();
            console.log('Socket disconnected on cleanup');
          }
        }
      } else {
        console.log('No token found');
      }
    } catch (error) {
      console.error('Error in useEffect:', error);
    }
  }, []);

  console.log('##App.js file connectedSocket', connectedSocket)

  return (
    <>
      <ToastContainer className="p-3" position='top-center'>
        <Toast show={modalShow} onClose={() => setModalShow(false)} delay={3000} bg={variant} className="text-white" autohide>

          {variant === 'success' ?
            <div className="p-1 m-1" style={{ backgroundColor: '#198754', color: 'white' }}  >
              <i className="fa-regular fa-circle-check text-white mx-2"></i>
              <strong className="me-auto">{title}</strong>
              <i className="fa-solid fa-xmark text-white float-right" style={{ float: 'right' }} role='button' onClick={() => setModalShow(false)}></i>
            </div>
            :
            <div className="p-1 m-1" style={{ backgroundColor: '#DC3545', color: 'white' }}  >
              <i className="fa-regular fa-circle-check text-white mx-2"></i>
              <strong className="me-auto">{title}</strong>
              <i className="fa-solid fa-xmark text-white float-right" style={{ float: 'right' }} role='button' onClick={() => setModalShow(false)}></i>
            </div>
          }

          <Toast.Body>{message instanceof Array ? message.map((item) => {
            return <span>{item.msg}</span>
          }) : message instanceof Object ? <span>{message.detail}</span> : <span>{message}</span>}</Toast.Body>
        </Toast>
      </ToastContainer>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Main socket={connectedSocket} />}>
            <Route index element={<Home />} />

            <Route
              path="/users"
              element={
                permissions && permissions.indexOf(constants.MODIFY_ALL) >= 0 ?
                  <UserList />
                  : <Alert error-alert variant='danger' className="alert error-alert">
                    <i className="fa-solid fa-triangle-exclamation mx-2"></i>You haven’t have any access
                  </Alert>
              }
            />

            {/******** Show an User By Id *******/}
            <Route path="users/:id" element={<UserView />} />

            <Route path="/campaign" element={<Campaign />} />
            <Route path="/campaign/add" element={<CampaignAdd />} />
            <Route path="/campaign/view/:id" element={<CampaignView />} />

            <Route path="/whatsapp_template" element={<Templates />} />
            <Route path="/whatsapp_template/add" element={<TemplateAdd />} />

            <Route path="/whatsapp_messenger" element={<WhatsAppMessenger socket={connectedSocket} />} />
            <Route path="/whatsapp_setting" element={<WhatsAppSetting />} />



            <Route path="/contacts" element={<Contacts />} />
            <Route path="/contacts/e" element={<ContactAdd />} />
            <Route path="/contacts/view/:id" element={<ContactView />} />



            {/* // Added by shivani start */}
            <Route path="/leads" element={<LeadList />} />
            <Route path="leads/:id" element={<LeadView />} />
            <Route path="leads/e" element={<LeadEdit />} />
            <Route path="leads/:id/e" element={<LeadEdit />} />
            {/*  // Added by shivani end */}

          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
