/**
 * @author      Abdul Pathan
 * @date        Aug, 2024
 * @copyright   www.ibirdsservices.com
 */

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Col, Form, Row } from "react-bootstrap";

const RemoveTemplate = (props) => {
    const [isSpinner, setIsSpinner] = useState(false);

    const handleDelete = () => {
        setIsSpinner(true);
        setTimeout(() => {
            props.removeSelectedRow();
        }, 1000);
    };

    return (
        <>
            <Modal show={props.show} aria-labelledby="contained-modal-title-vcenter" centered size='md'>
                <Modal.Header closeButton onClick={props.onHide} className=''>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {props.title}<br />
                    </Modal.Title>
                </Modal.Header>

                {!isSpinner ? <>
                    <Modal.Body>
                        <Row>
                            <Col lg={12} sm={12} xs={12}>
                                <Form noValidate className="mb-0">
                                    <h5 className="pb-3">Are you sure to delete template?</h5>
                                    <i>{props.message}</i>
                                </Form>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-flex justify-content-end">
                            <button className='btn btn-outline-primary' onClick={props.onHide}> No </button>
                            {props.table === "template" && (
                                <button className='btn btn-outline-primary mx-2' onClick={handleDelete}>Yes </button>
                            )}
                        </div>
                    </Modal.Footer>
                </>
                    :
                    <div className="sk-cube-grid">
                        <div className="sk-cube sk-cube1"></div>
                        <div className="sk-cube sk-cube2"></div>
                        <div className="sk-cube sk-cube3"></div>
                        <div className="sk-cube sk-cube4"></div>
                        <div className="sk-cube sk-cube5"></div>
                        <div className="sk-cube sk-cube6"></div>
                        <div className="sk-cube sk-cube7"></div>
                        <div className="sk-cube sk-cube8"></div>
                        <div className="sk-cube sk-cube9"></div>
                    </div>
                }
            </Modal>
        </>
    )
}

export default RemoveTemplate
