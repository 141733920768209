/**
 * @author      Abdul Pathan
 * @date        Aug, 2024
 * @copyright   www.ibirdsservices.com
 */

import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Card, Dropdown, } from 'react-bootstrap';
import WhatsAppAPI from '../../api/WhatsAppAPI';
import { toast } from 'react-toastify';//npm i react-toastify --force
import 'react-toastify/dist/ReactToastify.css';
import TemplateModal from './TemplateModal';
import moment from 'moment';
import SendFileModal from './SendFileModal';

const WhatsAppChat = ({ show, onHide, userDetail, socket }) => {
    const [input, setInput] = useState('');
    const [messages, setMessages] = useState([]);
    const [receivedMessage, setReceivedMessage] = useState();
    const [showHideModal, setShowHideModal] = useState(false);
    const [contactData, setContactData] = useState();
    const chatWindowRef = useRef(null);
    const [fileModalShowHide, setFileModalShowHide] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [audioBlob, setAudioBlob] = useState(null);
    const mediaRecorderRef = useRef(null);
    const pdfIcon = '/user_images/pdf-icon-png.png';
    const docxIcon = '/user_images/docx-icon-png.png';
    const imageIcon = '/user_images/image-icon-png.png';

    useEffect(() => {
        socket?.on("receivedwhatsappmessage", (data) => {
            // console.log("# chat connected on client", data);
            setReceivedMessage(data);
        })

        return () => {
            socket?.off("receivedwhatsappmessage");
        };
    }, [socket]);

    useEffect(() => {
        if (chatWindowRef.current) {
            chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
        }
    }, [messages]);


    useEffect(() => {
        if (userDetail?.whatsapp_number) {
            getMessageHistotyRecords();
        }
    }, [userDetail?.whatsapp_number, receivedMessage]);


    const getMessageHistotyRecords = async () => {
        if (userDetail?.whatsapp_number) {
            const result = await WhatsAppAPI.getMsgHistoryRecords(userDetail.whatsapp_number);
            console.log('####History', result)
            if (result.success) {
                setMessages(result?.records);
            } else {
                setMessages([]);
            }
        }
    }

    // send whatsapp template message
    const sendTemplateMessage = async () => {
        if (userDetail.id && userDetail.whatsapp_number) {
            setShowHideModal(true);
            setContactData(userDetail)
        } else {
            toast.error('Error: WhatsApp number is required.');
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSubmit();
        }
    }

    // contact send signle text messages
    const handleSubmit = async (event) => {
        // event.preventDefault();

        if (userDetail.whatsapp_number) {

            if (input.trim()) {
                const singleText = {
                    messaging_product: "whatsapp",
                    recipient_type: "individual",
                    to: userDetail?.whatsapp_number,
                    type: "text",
                    text: {
                        preview_url: false,
                        body: input
                    }
                }
                console.log('###singleText', singleText)

                const result = await WhatsAppAPI.sendSingleWhatsAppTextMessage(singleText);

                if (result.error) {
                    toast.error(`Error: ${result.error}`);
                } else {
                    console.log('###single Message success', result);

                    const newMessage = {
                        parent_id: userDetail.id || null,
                        name: userDetail.contactname || '',
                        template_name: '',
                        to_number: userDetail.whatsapp_number,
                        message: input,
                        status: 'Outgoing',
                        recordtypename: '',
                        file_id: null,
                    }

                    const responce = await WhatsAppAPI.insertMsgHistoryRecords(newMessage);
                    console.log('##responce', responce);

                    getMessageHistotyRecords();
                    toast.success("Message sent successfully.");
                    setInput('');
                }
            } else {
                toast.error("Error: Input field required, please enter text here.",);
            }
        } else {
            toast.error('WhatsApp number is required.');
        }
    };

    const refreshData = () => {
        getMessageHistotyRecords();
        setShowHideModal();
    }
    const refreshImageData = () => {
        getMessageHistotyRecords();
        setFileModalShowHide();
    }

    //close
    const onCancelButton = () => {
        onHide(false)
    }

    const onUploadFile = () => {
        if (userDetail.id) {
            setFileModalShowHide(true);
        }
    }


    const startRecording = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        mediaRecorderRef.current = new MediaRecorder(stream);

        const chunks = [];
        mediaRecorderRef.current.ondataavailable = (e) => {
            chunks.push(e.data);
        };

        mediaRecorderRef.current.onstop = async () => {
            const blob = new Blob(chunks, { type: 'audio/mpeg' });
            setAudioBlob(blob);

            // Stop all audio tracks to release the microphone
            stream.getTracks().forEach(track => track.stop());

            // Automatically save the recording
            await saveRecording(blob);
        };

        mediaRecorderRef.current.start();
        setIsRecording(true);
    };

    const stopRecording = () => {
        mediaRecorderRef.current.stop();
        setIsRecording(false);
    };

    const saveRecording = async (blob) => {
        console.log('userDetail.id', userDetail.id)
        if (blob) {
            const body = new FormData();
            body.append('file', blob, `audio_${Date.now()}.mp3`);
            body.append("messaging_product", "whatsapp");
            body.append("description", 'Outgoing');
            console.log("userDetail?.whatsapp_number", userDetail?.whatsapp_number);
            // debugger;
            try {
                const documentId = await WhatsAppAPI.uploadDocumentWithApi(body);
                console.log('###result file id: ', documentId);
                console.log('###result file id: ', body);

                if (documentId.id) {

                    const reqBody = {
                        messaging_product: 'whatsapp',
                        recipient_type: 'individual',
                        to: userDetail.whatsapp_number,
                        type: 'audio',
                        audio: {
                            id: documentId.id
                        }
                    };
                    console.log("reqBody", reqBody)
                    const response = await WhatsAppAPI.sendWhatsAppFiles(reqBody);
                    console.log('#####finally send image', response);
                    // debugger;
                    if (response?.error) {
                        console.error('Error in sending message:', response.error);
                    }

                    if (response.messaging_product === 'whatsapp') {

                        console.log("bodybody", body);
                        const result = await WhatsAppAPI.createFile(userDetail.id, body);
                        console.log('####result', result);

                        if (result.errors) {
                            toast.error('Bad Request.');
                        }
                        if (result.success) {
                            const newMessage = {
                                parent_id: userDetail.id || null,
                                name: userDetail.contactname || '',
                                template_name: '',
                                to_number: userDetail.whatsapp_number,
                                message: '',
                                status: 'Outgoing',
                                recordtypename: '',
                                file_id: result.records[0].id || null,
                            };

                            const response = await WhatsAppAPI.insertMsgHistoryRecords(newMessage);
                            console.log('response', response);

                            toast.success('File sent successfully!');
                            getMessageHistotyRecords();
                        }
                        else {
                            toast.error('Failed to send file.');
                        }
                    }
                }
            } catch (error) {
                console.error('Error during file upload:', error);
                toast.error('An error occurred while sending the file.');
            }
        } else {
            toast.error("No audio data to upload. Please record audio first.");
            return;
        }
    };


    const handleAudio = async () => {
        if (!audioBlob) {
            console.error("Audio blob is not available");
            toast.error("No audio data to upload. Please record audio first.");
            return;
        }

        const body = new FormData();
        body.append('audio', audioBlob, `audio_${Date.now()}.wav`);
        body.append("messaging_product", "whatsapp");
        body.append("description", 'Outgoing');

        try {
            const result = await WhatsAppAPI.createFile(userDetail?.whatsapp_number, body);
            console.log('####result', result);

            if (result.success) {
                getMessageHistotyRecords();
                toast.success('File sent successfully!');
            } else {
                toast.error('Failed to send file.');
            }
        } catch (error) {
            console.error('Error during file upload:', error);
            toast.error('An error occurred while sending the file.');
        }
    };

    // const isImage = (message) => {
    //     return (message.match(/\.(jpeg|jpg|gif|png)$/) != null);
    // }



    const renderFilePreview = (msg) => {
        const isLink = msg.chatmsg && msg.chatmsg.match(/(https?:\/\/[^\s]+)/g);
        if (isLink) {
            return (
                <div style={{ marginTop: '10px' }}>
                    <a href={msg.chatmsg} target="_blank" rel="noopener noreferrer">
                        {msg.chatmsg}
                    </a>
                </div>
            );
        } else if (msg.chatmsg) {
            return (
                <div style={{ marginTop: '10px' }}>
                    {msg.chatmsg}
                </div>
            );
        }

        const fileUrl = `/user_images/${msg.title}`;

        switch (msg.filetype) {
            case 'jpeg':
            case 'jpg':
            case 'png':
                return (
                    <div style={{ marginTop: '10px' }}>
                        <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                            <img
                                src={fileUrl}
                                alt={msg.title}
                                style={{ maxWidth: '100%', maxHeight: '200px' }}
                            />
                        </a>
                    </div>
                );
            case 'pdf':
                return (
                    <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                        <img
                            src={pdfIcon}
                            alt="PDF Icon"
                            style={{ width: '40px', height: '40px', marginRight: '10px' }}
                        />
                        <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                            {msg.title}
                        </a>
                    </div>
                );
            case '.wav':
            case 'mp3':
                return (
                    <div style={{ marginTop: '10px' }}>
                        <audio controls src={fileUrl} />
                    </div>
                );
            case 'mp4':
                return (
                    <div style={{ marginTop: '10px' }}>
                        <video controls style={{ maxWidth: '100%', maxHeight: '200px' }}>
                            <source src={fileUrl} type="video/mp4" />
                            Your browser does not support the video element.
                        </video>
                    </div>
                );
            case 'docx':
                return (
                    <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                        <img
                            src={docxIcon}
                            alt="DOCX Icon"
                            style={{ width: '40px', height: '40px', marginRight: '10px' }}
                        />
                        <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                            {msg.title}
                        </a>
                    </div>
                );
            default:
                return (
                    <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                        <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                            {msg.title}
                        </a>
                    </div>
                );
        }
    };

    const hasOutgoingMessage = messages?.some(message => message.status === 'Incoming');


    return (
        <>
            <Card className='h-100' >

                <Card.Header className='p-3'>
                    <Row className='g-0' >
                        <Col lg={7} xs={6} sm={6} style={{ display: "flex", alignItems: "stretch", }}>
                            <img src="img_man_icon.png" alt="" className="rounded-circle" style={{ height: "27px", width: "27px" }} />
                            <span className='fw-bold mx-3' style={{ fontSize: "15px", color: "white" }}>
                                {userDetail?.contactname} <span className='fw-light'>{userDetail?.whatsapp_number}</span>
                            </span>
                        </Col>
                        <Col lg={5} xs={3} sm={3} className='text-end'>
                            <Dropdown>
                                <Dropdown.Toggle style={{ border: "none" }} title='Action'>
                                    <i className="fa-solid fa-ellipsis-vertical"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu >
                                    <Dropdown.Item onClick={sendTemplateMessage}>Send</Dropdown.Item>
                                    <Dropdown.Item onClick={onCancelButton}>Close</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </Card.Header>

                {messages?.length > 0 ? (
                    <div className='chat-window' style={{ height: '35rem', overflowY: 'scroll', overflowX: 'hidden', background: '#efe7dd url("https://cloud.githubusercontent.com/assets/398893/15136779/4e765036-1639-11e6-9201-67e728e86f39.jpg") repeat' }}>
                        <Card.Body className='mb-5'>
                            <Row className='g-0'>
                                <Col lg={12} sm={12} xs={12} className='mb-2'>
                                    {messages?.map((msg, index) => (
                                        <div className='conversation' key={index}>
                                            <div className='conversation-container'>
                                                <div className={`message ${msg.status === 'Incoming' ? 'received' : 'sent'}`}>
                                                    {/* <div>
                                                        {msg.chatmsg || msg.templatemsg}
                                                        {msg.file_id && renderFilePreview(msg)}
                                                    </div> */}
                                                    <div>
                                                        {msg.templatemsg}
                                                        {renderFilePreview(msg)}
                                                    </div>
                                                    <span className='metadata'>
                                                        <span className='time'>
                                                            {/* {moment(msg.createddate, 'HH:mm:ss').format('HH:mm')}  {moment(msg.createddate, 'HH:mm:ss.SSSSSS').format('DD-MM-YYYY')} */}
                                                            {/* {moment(msg.createddate, 'HH:mm:ss.SSSSSS').format('HH:mm DD-MM-YYYY')} */}
                                                            {moment(msg.createddate).format('M/D/YYYY, h:mm A')}
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Col>
                            </Row>
                        </Card.Body>
                    </div>
                ) :
                    <div style={{ height: "100%", background: '#efe7dd url("https://cloud.githubusercontent.com/assets/398893/15136779/4e765036-1639-11e6-9201-67e728e86f39.jpg") repeat' }}>
                        <Row className='g-0'>
                            <Col lg={12} sm={12} xs={12} className='p-4'>
                                <span style={{ fontSize: "15px" }}>
                                    There are no chats available here. Sending your first message is necessary because you haven't chatted before.
                                    Send your initial message using the Send button below.
                                </span>
                            </Col>
                            <Col lg={12} sm={12} xs={12} className='text-center p-2'>
                                <button type="button" className="btn btn-sm btn-light mx-1" onClick={sendTemplateMessage}>
                                    Send Message
                                </button>
                            </Col>

                            <Col lg={12} sm={12} xs={12} className='p-4 mt-5 text-center'>
                                <p>
                                    <i className="fa-brands fa-whatsapp" style={{ fontSize: "30px", color: "rgb(119 110 102)" }}></i>
                                </p>
                                <p>WhatsApp for Windows</p>
                                <p>Send and recieve messages</p>
                            </Col>

                        </Row>
                    </div>
                }


                {hasOutgoingMessage ? (
                    <Card.Footer>
                        <Row className='g-0 mt-2'>
                            <Col lg={11} sm={8} xs={8}>
                                <div className="input-focus-class" style={{ position: 'relative', display: 'inline-block', width: '100%' }} >
                                    <input
                                        type="text"
                                        placeholder="Type message here..."
                                        value={input}
                                        onChange={(e) => setInput(e.target.value)}
                                        onKeyDown={handleKeyDown}
                                        disabled={messages?.length <= 0}
                                        style={{
                                            width: '100%',
                                            padding: '10px 40px 10px 10px',
                                            borderRadius: '20px',
                                            border: 'none',
                                        }}
                                    />

                                    <i
                                        className={`fa-solid fa-microphone mic-button ${isRecording ? 'recording' : ''}`}
                                        style={{
                                            position: 'absolute',
                                            right: '50px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            fontSize: '18px',
                                            color: isRecording ? '#007BFF' : '#999fab', // Blue when recording, grey otherwise
                                            cursor: 'pointer',
                                        }}
                                        onClick={isRecording ? stopRecording : startRecording}
                                    ></i>
                                    <i className="fa-solid fa-paperclip"
                                        style={{
                                            position: 'absolute',
                                            right: '15px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            fontSize: "18px",
                                            color: "#999fab",
                                            cursor: "pointer"
                                        }}
                                        onClick={onUploadFile}
                                    ></i>
                                </div>
                            </Col>
                            {/* <Col lg={1} xs={4} sm={4} className='text-center'>
                                <button type="button" className="btn btn-outline-secondary mx-1 mt-1" onClick={handleSubmit}>
                                    Send
                                </button>
                            </Col> */}
                            <Col lg={1} xs={4} sm={4} className='text-center'>
                                <button type="button" className="btn btn-outline-secondary mx-1 mt-1" onClick={handleSubmit}>
                                    <i className="fa fa-paper-plane"></i>
                                </button>
                            </Col>
                        </Row>
                    </Card.Footer >

                ) :
                    <div style={{
                        height: "100%",
                        background: '#efe7dd url("https://cloud.githubusercontent.com/assets/398893/15136779/4e765036-1639-11e6-9201-67e728e86f39.jpg") repeat'
                    }}>
                    </div>
                }

            </Card>



            {showHideModal &&
                <TemplateModal
                    show={showHideModal}
                    onHide={() => setShowHideModal(false)}
                    contactData={contactData}
                    refreshData={refreshData}
                />
            }

            {fileModalShowHide &&
                <SendFileModal
                    show={fileModalShowHide}
                    onHide={() => setFileModalShowHide(false)}
                    refreshImageData={refreshImageData}
                    parentData={userDetail}
                />
            }
        </>
    )
}

export default WhatsAppChat