/** @format */
import Sidebar from "./Sidebar"
import Header from "./Header";
import { Outlet, Link } from "react-router-dom";
const Main = ({ socket }) => {
  return (
    <div className="wrapper">
      <Sidebar />
      <div id="content">
        <Header socket={socket} />
        <Outlet />
      </div>
    </div>

  );
};

export default Main;