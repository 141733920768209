/**
 * @author      Abdul Pathan
 * @date        September, 2024
 * @copyright   www.ibirdsservices.com
 */

import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Form, } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WhatsAppAPI from '../../api/WhatsAppAPI';

const SendFileModal = (props) => {
    const [files, setFiles] = useState([]);
    const [isSending, setIsSending] = useState(false);
    const [isFileSelected, setIsFileSelected] = useState(false);
    const [parentRecord, setParentRecord] = useState(props.parentData || {});
    const [fileDescription, setFileDescription] = useState('');

    console.log('Send File Modal', parentRecord)

    const MIMEType = new Map([
        ["text/csv", "csv"],
        ["application/msword", "doc"],
        ["application/vnd.openxmlformats-officedocument.wordprocessingml.document", "docx"],
        ["image/gif", "gif"],
        ["text/html", "html"],
        ["image/jpeg", "jpeg"],
        ["image/jpg", "jpg"],
        ["application/json", "json"],
        ["audio/mpeg", "mp3"],
        ["video/mp4", "mp4"],
        ["image/png", "png"],
        ["application/pdf", "pdf"],
        ["application/vnd.ms-powerpoint", "ppt"],
        ["application/vnd.openxmlformats-officedocument.presentationml.presentation", "pptx"],
        ["image/svg+xml", "svg"],
        ["text/plain", "txt"],
        ["application/vnd.ms-excel", "xls"],
        ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "xlsx"],
        ["text/xm", "xml"],
        ["application/xml", "xml"],
        ["application/atom+xml", "xml"],
        ["application/zip", "zip"],
    ]);

    const handleChange = (event) => {
        const selectedFiles = event.target.files;
        setFiles(selectedFiles);
        setIsFileSelected(selectedFiles.length > 0);
        console.log('##handleChange', event.target.files[0])
    }

    const getWhatsAppType = (mimeType) => {
        if (mimeType.startsWith('image/')) return 'IMAGE';
        if (mimeType.startsWith('video/')) return 'VIDEO';
        // if (mimeType.startsWith('audio/')) return 'AUDIO';
        if (mimeType === 'application/pdf') return 'DOCUMENT';
        if (mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || mimeType === 'application/msword') return 'DOCUMENT';
        if (mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || mimeType === 'application/vnd.ms-excel') return 'DOCUMENT';
        if (mimeType === 'text/csv') return 'DOCUMENT';

        return 'DOCUMENT';
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (parentRecord.id) {
            if (files.length > 0) {

                setIsSending(true); // Set submitting state to true

                let current = new Date();
                const formData = new FormData();

                // for (let i = 0; i < files.length; i++) {
                //     formData.append("messaging_product", "whatsapp");
                //     formData.append(`file`, files[i]);
                //     formData.append(`description`, fileDescription);
                // }

                formData.append("messaging_product", "whatsapp");
                formData.append("file", files[0]);
                formData.append(`description`, fileDescription);

                try {
                    const documentId = await WhatsAppAPI.uploadDocumentWithApi(formData);
                    console.log('###result file id: ', documentId);

                    if (documentId.id) {
                        const fileType = getWhatsAppType(files[0].type);

                        const reqBody = {
                            messaging_product: 'whatsapp',
                            recipient_type: 'individual',
                            to: parentRecord.whatsapp_number,
                            type: fileType,
                            [fileType.toLowerCase()]: {
                                id: documentId.id
                            }
                        };


                        const response = await WhatsAppAPI.sendWhatsAppFiles(reqBody);
                        console.log('#####finally send image', response);

                        if (response.messaging_product === 'whatsapp') {

                            const result = await WhatsAppAPI.createFile(parentRecord.id, formData);
                            console.log('####result', result);

                            if (result.errors) {
                                toast.error('Bad Request.');
                            }

                            if (result.success) {
                                const newMessage = {
                                    parent_id: parentRecord.id || null,
                                    name: parentRecord.contactname || '',
                                    template_name: '',
                                    to_number: parentRecord.whatsapp_number,
                                    message: '',
                                    status: 'Outgoing',
                                    recordtypename: '',
                                    file_id: result.records[0].id || null,
                                };

                                const response = await WhatsAppAPI.insertMsgHistoryRecords(newMessage);
                                console.log('response', response);

                                toast.success('File sent successfully!');
                                props.refreshImageData();
                            }
                            else {
                                toast.error('Failed to send file.');
                            }
                        }
                    }

                } catch (error) {
                    console.error('Error during file upload:', error);
                    toast.error('An error occurred while sending the file.');
                } finally {
                    setIsSending(false); // Reset submitting state
                }
            } else {
                toast.error('Please select a file.');
            }
        }
    };


    return (

        <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
            {/* <Form onSubmit={handleSubmit}> */}
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Upload Files
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form.Group controlId="formFile" className="mb-5 mt-2">
                    <Form.Label></Form.Label>
                    <Form.Control type="file" onChange={handleChange} accept="image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,video/*" />
                    {/* multiple */}
                    {/* accept='.pdf,.doc,.docx,.jpg,.jpeg,.png,.mp4,.avi' */}
                </Form.Group>

                <Form.Group controlId="formBasicDescription">
                    <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicDescription"
                    >
                        Description
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        name="description"
                        placeholder="Enter description"
                        onChange={(e) => setFileDescription(e.target.value)}
                    />
                </Form.Group>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={props.onHide}>
                    Close
                </Button>
                <div className="submit">
                    <Button variant="outline-primary" type="button" onClick={handleSubmit} disabled={!isFileSelected || isSending}>
                        {isSending ? 'Sending...' : 'Send'}
                    </Button>
                </div>
            </Modal.Footer>
            {/* </Form> */}
        </Modal >
    )
}

export default SendFileModal
