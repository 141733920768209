/**
 * @author      Abdul Pathan
 * @date        Aug, 2024
 * @copyright   www.ibirdsservices.com
 */

import React, { useEffect, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import WhatsAppAPI from '../../api/WhatsAppAPI';
import { toast } from 'react-toastify';//npm i react-toastify --force
import 'react-toastify/dist/ReactToastify.css';

const TemplateModal = ({ show, onHide, contactData, refreshData }) => {
    const [allTemplateData, setAllTemplateData] = useState([]);
    const [selectedTemplateName, setSelectedTemplateName] = useState();
    const [isSpinner, setIsSpinner] = useState(false)

    useEffect(() => {
        fetchAllTemplate();
    }, []);

    const fetchAllTemplate = async () => {
        const result = await WhatsAppAPI.getAllTemplateData();

        if (result.error) {
            setAllTemplateData([])
            setIsSpinner(true);
        } else {
            const transformedData = result?.data.map(row => {
                const header = row.components.find(component => component.type === 'HEADER') || {};
                const body = row.components.find(component => component.type === 'BODY') || {};
                const footer = row.components.find(component => component.type === 'FOOTER') || {};

                return {
                    template_id: row.id,
                    template_name: row.name,
                    templatename: row.name.replace(/_/g, ' '),
                    category: row.category,
                    status: row.status,
                    language: row.language,
                    header_text: header.format === 'TEXT' ? header.text : '',
                    header_image_url: header.format === 'IMAGE' ? (header.example?.header_handle?.[0] || '') : '',
                    message: body.text || '',
                    footer: footer.text || '',
                    add_security_recommendation: body.add_security_recommendation || false,
                    code_expiration_minutes: footer.code_expiration_minutes || null
                };
            });

            console.log('transformedData', transformedData);
            setAllTemplateData(transformedData);
            setIsSpinner(true);
        }
    }

    const handleChange = async (event) => {
        const selectedName = event.target.value;
        const template = allTemplateData.find(t => t.template_name === selectedName);

        if (template) {
            const { template_id, template_name, message } = template;
            setSelectedTemplateName({ template_id, template_name, message })
        } else {
            setSelectedTemplateName();
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            setIsSpinner(false);

            const msgResult = await WhatsAppAPI.createMessageTemplateData(selectedTemplateName);
            console.log('#msgResult', msgResult)

            if (msgResult?.errors) {
                toast.error(msgResult?.errors);
                setIsSpinner(true);
                return;
            }

            const reqBody = {
                messaging_product: 'whatsapp',
                to: contactData.whatsapp_number,
                type: 'template',
                template: {
                    name: selectedTemplateName.template_name,
                    language: {
                        code: 'en_US',
                    },
                },
            };

            const result = await WhatsAppAPI.sendWhatsAppTemplateMessage(reqBody);

            if (result.error) {
                const msgError = result.error.error_data;
                toast.error(`Error: ${msgError.details}`);
                // toast.error(`Error: Message could not be sent to this number ${number} due to a bad request.`);
                console.log('##ERROR', result.error)
                setIsSpinner(true);
            } else {
                const newMessage = {
                    parent_id: contactData.id || null,
                    name: contactData?.contactname || '',
                    template_name: msgResult.template_name,
                    to_number: contactData.whatsapp_number,
                    message: '',
                    status: 'Outgoing',
                    recordtypename: '',
                    file_id: null,
                };

                const response = await WhatsAppAPI.insertMsgHistoryRecords(newMessage);
                console.log('response', response);
            }
        } catch (error) {
            toast.error("Failed to send message.");
            setIsSpinner(true);
        } finally {
            setIsSpinner(true);
            refreshData();
        }
    }

    const isFormValid = Boolean(selectedTemplateName);

    return (
        <>
            <Modal show={show} animation={false} size='md' >
                <Modal.Header closeButton onClick={onHide}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Send WhatsApp Message
                    </Modal.Title>
                </Modal.Header>
                {isSpinner ?
                    <>
                        <Modal.Body>
                            <Form noValidate >
                                <Row className='p-2 mb-3'>
                                    <Col lg={12} sm={12} xs={12}>
                                        <Form.Group className='mb-3'>
                                            <Form.Label className="form-view-label" htmlFor="formBasicFirstName">
                                                Template Name
                                            </Form.Label>
                                            <Form.Select
                                                aria-label="select name"
                                                name="templateName"
                                                onChange={handleChange}
                                                placeholder='Select Template Name'
                                            // value={templateName}
                                            >
                                                <option value="">Select Template Name</option>
                                                {allTemplateData?.map((template) => (
                                                    <option key={template.template_id} value={template.template_name}>
                                                        {template.templatename}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal.Body>

                        <Modal.Footer>
                            <button className='btn btn-outline-primary' onClick={onHide} >Close</button>
                            <button className='btn btn-outline-primary' onClick={handleSubmit} disabled={!isFormValid}>Send Message</button>
                        </Modal.Footer>
                    </>
                    :
                    <div className="sk-cube-grid">
                        <div className="sk-cube sk-cube1"></div>
                        <div className="sk-cube sk-cube2"></div>
                        <div className="sk-cube sk-cube3"></div>
                        <div className="sk-cube sk-cube4"></div>
                        <div className="sk-cube sk-cube5"></div>
                        <div className="sk-cube sk-cube6"></div>
                        <div className="sk-cube sk-cube7"></div>
                        <div className="sk-cube sk-cube8"></div>
                        <div className="sk-cube sk-cube9"></div>
                    </div>
                }
            </Modal>
        </>
    )
}

export default TemplateModal
