import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';


const DoughnutChart = () => {
    ChartJS.register(ArcElement, Tooltip, Legend);
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'Properties sales monthly',
            },
        },
    };
    const data = {
        labels: ['Authentication', 'Marketing', 'Utility'],
        datasets: [
            {
                label: '# of Votes',
                data: [30, 30, 40],
                backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)',],
                borderColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)',],
                borderWidth: 1,
            },
        ],
    };
    return (
        <Doughnut data={data} options={options} />
    )
}

export default DoughnutChart
