/**
 * @author      Abdul Pathan
 * @date        Aug, 2024
 * @copyright   www.ibirdsservices.com
 */

import React, { useEffect, useState } from 'react';
import { Form, Container, Row, Col, Card } from 'react-bootstrap';
import WhatsAppChat from './WhatsAppChat';
import WhatsAppAPI from '../../api/WhatsAppAPI';
import { ToastContainer, toast } from 'react-toastify';//npm i react-toastify --force
import 'react-toastify/dist/ReactToastify.css';
import TemplateModal from './TemplateModal';


const WhatsAppMessenger = ({ socket }) => {
    const [body, setBody] = useState([]);
    const [showHide, setShowHide] = useState(false);
    // const [userNameAndNumber, setUserNameAndNumber] = useState();
    const [userData, setUserData] = useState();
    const [filters, setFilters] = useState({ textName: '', cityName: '', recordType: 'contact' });
    const [receivedMessage, setReceivedMessage] = useState();
    const [showHideModal, setShowHideModal] = useState(false);
    const [records, setRecords] = useState();

    console.log('####socket WhatsAppMessenger', socket)

    useEffect(() => {
        socket?.on("receivedwhatsappmessage", (data) => {
            console.log("#@connected on client", data);
            setReceivedMessage(data);
        })
    }, [socket]);

    console.log('@@receivedMessage', receivedMessage)

    useEffect(() => {
        fetchData();
    }, [filters, receivedMessage]);


    const fetchData = async () => {
        const { textName, cityName, recordType } = filters;
        console.log('filters', filters)

        let result;
        if (recordType === 'contact') {
            result = await WhatsAppAPI.getAllContactRecords(textName, cityName);
            console.log('result: ', result)
        } else {
            result = await WhatsAppAPI.getLeadData(textName, cityName);
        }

        console.log('$$$$$$$$$$$$$$', result)
        if (result.success) {
            setBody(result.records);
        } else {
            setBody([]);
        }

        console.log('body.length', body.length)
    };

    const handleChange = async (event) => {
        setShowHide(false)
        const { name, value } = event.target;
        setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
    }

    const handleUserClick = (item) => {
        console.log('###itme', item);
        setShowHide(true)
        setUserData(item);
        // setUserNameAndNumber({ userName: item.contactname, whatsappNumber: item.whatsapp_number });
    };

    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);

    const handleSelectAll = () => {
        setSelectAll(!selectAll);
        if (!selectAll) {
            setSelectedItems(body.map(item => item.whatsapp_number));
        } else {
            setSelectedItems([]);
        }
    };

    const handleCheckboxChange = (whatsappNumber) => {
        if (selectedItems.includes(whatsappNumber)) {
            setSelectedItems(selectedItems.filter(item => item !== whatsappNumber));
        } else {
            setSelectedItems([...selectedItems, whatsappNumber]);
        }
    };

    const sendMessagesBulk = (e) => {
        console.log('##selected Items', selectedItems)
        setShowHide(false);
        if (selectedItems.length > 0) {
            setShowHideModal(true);
            setRecords(selectedItems);
        } else {
            toast.error("Error: Check the box for at least one list.",);
        }
    }

    const refreshData = () => {
        fetchData();
        setShowHideModal();
    }


    return (
        <>
            <Container className='mt-5'>
                <Row className='g-0 mx-5 text-center'>
                    <Col lg={12} xs={12} sm={12}>
                        <div className=' text-center p-2' style={{ height: '40px', backgroundColor: '#ffffff', borderRadius: '5px' }}>
                            <span className='fw-semibold p-1' style={{ color: '#605C68', fontSize: 'large' }}>
                                WhatsApp for Windows
                            </span>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container className='mt-2 mb-5'>
                <Row className='g-0 mx-5'>
                    <Col lg={5} sm={12} xs={12} className="mb-3">
                        <Card className='h-100' >
                            <Card.Header className='p-3'>
                                <Row className='g-0 ' >
                                    <Col lg={8} xs={6} sm={6} style={{ display: "flex", alignItems: "stretch", }}>
                                        <i className="fa-brands fa-whatsapp" style={{ fontSize: "27px", color: "white" }}></i>
                                        <span className='mx-3 fw-bold' style={{ fontSize: "15px", color: "white" }}>
                                            Start Info Tech College
                                        </span>
                                    </Col>
                                    <Col lg={4} xs={6} sm={6} className='text-end'>
                                        <button type="button" className="btn btn-sm btn-light" onClick={sendMessagesBulk}>
                                            Send Bulk Messages
                                        </button>
                                    </Col>
                                </Row>
                            </Card.Header>

                            <Card.Body className='mb-5'>
                                <div style={{ height: '30rem' }}>
                                    <Row className='g-0'>
                                        <Col lg={12} sm={12} xs={12} className='mb-2'>
                                            <Row className='mt-2'>
                                                <Col lg={4} xs={12} sm={12}>
                                                    <Form.Group className='mb-3'>
                                                        <Form.Control
                                                            type="text"
                                                            name="textName"
                                                            placeholder='Search...'
                                                            onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={4} xs={12} sm={12}>
                                                    <Form.Group className='mb-3'>
                                                        <Form.Select
                                                            aria-label="select name"
                                                            name="cityName"
                                                            onChange={handleChange}
                                                            placeholder='Select City Name'
                                                        >
                                                            <option value="">Select City</option>
                                                            <option value="Ajmer">Ajmer</option>
                                                            <option value="Jaipur">Jaipur</option>
                                                            <option value="Nagaur">Nagaur</option>
                                                            <option value="Kota">Kota</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={4} xs={12} sm={12}>
                                                    <Form.Group className='mb-3'>
                                                        <Form.Select
                                                            aria-label="select name"
                                                            name="recordType"
                                                            onChange={handleChange}
                                                        >
                                                            <option value="contact">Contact</option>
                                                            <option value="lead">Lead</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <hr></hr>

                                        <div className="table-container mt-3" style={{ maxHeight: "25rem", overflowY: "auto" }}>
                                            <Col lg={12} sm={12} xs={12}>
                                                {body.length > 0 ?
                                                    <table className="table" >
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    <input
                                                                        className='mx-1'
                                                                        name='selectName'
                                                                        type="checkbox"
                                                                        checked={selectAll}
                                                                        onChange={handleSelectAll}
                                                                        style={{ cursor: "pointer" }}
                                                                    />
                                                                </th>
                                                                <th className='px-5'>Name</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {body?.map(item => (
                                                                <tr key={item.id}>
                                                                    <td className='pt-3'>
                                                                        <input
                                                                            className='mx-1'
                                                                            name='selectName'
                                                                            type="checkbox"
                                                                            checked={selectedItems.includes(item.whatsapp_number)}
                                                                            onChange={() => handleCheckboxChange(item.whatsapp_number)}
                                                                            style={{ cursor: "pointer" }}
                                                                        />
                                                                    </td>
                                                                    <td onClick={() => handleUserClick(item)} style={{ cursor: "pointer" }}>
                                                                        <img src="img_man_icon.png" alt="" className="rounded-circle" style={{ height: "32px", width: "33px" }}></img>
                                                                        <span className='mx-2' style={{ fontSize: "15px" }}>
                                                                            {item.contactname}
                                                                            <i className='mx-2' style={{ fontSize: "10px" }}>( {item.whatsapp_number} )</i>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    :
                                                    'No record exists.'
                                                }
                                            </Col>
                                        </div>
                                    </Row>
                                </div>
                            </Card.Body>

                            <Card.Footer>
                                <Row className='g-0 mt-3'>
                                    <Col lg={6} sm={12} xs={12} className="mt-1">
                                        <p><b>Total Records: {body?.length}</b></p>
                                    </Col>
                                </Row>
                            </Card.Footer>
                        </Card>
                    </Col>

                    {showHide &&
                        <Col lg={7} sm={12} xs={12} className="mb-3">
                            <WhatsAppChat
                                show={showHide}
                                onHide={() => setShowHide(false)}
                                // userDetail={userNameAndNumber}
                                userDetail={userData}
                                socket={socket}
                            />
                        </Col>
                    }
                </Row>
            </Container>
            <ToastContainer />

            {showHideModal &&
                <TemplateModal
                    show={showHideModal}
                    onHide={() => setShowHideModal(false)}
                    records={records}
                    refreshData={refreshData}
                />
            }
        </>
    )
}

export default WhatsAppMessenger
