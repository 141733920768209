import React, { useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import authApi from "../api/authApi";
import WhatsAppAPI from "../api/WhatsAppAPI";
import * as constants from '../constants/CONSTANT';

const Login = () => {
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({ email: "abdul.h@ibirdsservices.com", password: "Admin@123" });
  const [show, setShow] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log(credentials);

    const result = await authApi.login(credentials.email, credentials.password);
    if (result.success) {
      console.log('result.authToken', result.authToken)
      localStorage.setItem("token", result.authToken);

      const response = await WhatsAppAPI.getWhatsAppSettingRecord('Abdul Pathan');
      console.log('WhatsApp Setting Data', response.record[0])
      if (response.success) {
        localStorage.setItem('whatsapp_setting', JSON.stringify(response.record[0]));
      }

      let data = '';
      if (data)
        localStorage.setItem("myimage", window.URL.createObjectURL(data));
      else
        localStorage.setItem("myimage", "/abdul-pathan.png");

      let settingResult = await WhatsAppAPI.fetchCompanySetting('lead_status_setting');
      if (settingResult && settingResult.setting) {
        localStorage.setItem('lead_status', settingResult.setting.configuration);
      } else {
        localStorage.setItem('lead_status', JSON.stringify(constants.LEAD_STATUS_VALUES));
      }
      navigate("/");
    } else {
      setShow(true);
      setErrorMessage(result.errors);
    }
  };

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  return (
    <Container>
      <Row className="login-form p-3 mt-5">
        <Col lg={6} className="pt-5">
          <img src="login.jpg" style={{ width: "100%" }} alt="" />

        </Col>
        <Col lg={6} className="login-section">
          <center></center>
          <div className="p-5 pt-4">
            <Form onSubmit={handleSubmit}>
              <div className="mt-2 text-center mb-3">
                <img
                  src="logo.png"
                  style={{ width: "120px" }}
                  className="mb-2" alt="/"
                />
                <div>Convert, before it fades away</div>
                <h3 className="my-2 mt-5">Login</h3>
              </div>
              <Alert variant="danger" show={show} className="error-alert">
                {errorMessage}
              </Alert>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  onChange={handleChange}
                  placeholder="Enter email"
                  value={credentials.email}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  onChange={handleChange}
                  placeholder="Password"
                  value={credentials.password}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Login
              </Button>
            </Form>
            <div className="pt-4 text-center">
              {/* or Login with */}
              <div className="pt-4">
                <a href="/#">
                  <span class="fa-stack fa-2x">
                    <i
                      class="fa-solid fa-circle fa-stack-2x"
                      style={{ color: "#3b5998", fontSize: "4rem" }}
                    ></i>
                    <i class="fa-brands fa-facebook-f fa-stack-1x" style={{ color: "#fff", fontSize: "2rem" }}></i>
                  </span>
                </a>
                <a href="/#">
                  <span class="fa-stack fa-2x">
                    <i
                      class="fa-solid fa-circle fa-stack-2x"
                      style={{ color: "tomato", fontSize: "4rem" }}
                    ></i>
                    <i class="fa-brands fa-google fa-stack-1x" style={{ color: "#fff", fontSize: "2rem" }}></i>
                  </span>
                </a>
                <a href="/#">
                  <span class="fa-stack fa-2x">
                    <i
                      class="fa-solid fa-circle fa-stack-2x"
                      style={{ color: "#0d95e8", fontSize: "4rem" }}
                    ></i>
                    <i class="fa-brands fa-twitter fa-stack-1x" style={{ color: "#fff", fontSize: "2rem" }}></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className="text-center">
        <h6 className="text-center mt-3"> Powered by</h6>
        <img src="logo.png" width="150px" alt="" />
      </div>
    </Container>
  );
};

export default Login;
