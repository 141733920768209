import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";


const ContactView = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [contactRecord, setContactRecord] = useState(location.state ? location.state : {});

  console.log("contact=>" + JSON.stringify(contactRecord));

  const handleBack = () => {
    navigate('/contacts');
  }


  return (
    <>

      <Container className='mt-5'>
        <Row className='mx-5'>
          <Col lg={12} sm={12} xs={12} className="section-header">
            <Row>
              <Col lg={8} sm={12} xs={12} className="mt-1">
                Contact Record Details
              </Col>
              <Col lg={4} sm={4} xs={4} className="text-end"  >
                <Button className='mx-1 btn-sm' variant="light" onClick={handleBack}>
                  <i class="fa-solid fa-arrow-left" title="Back"></i>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Container className='mt-1'>
        <Row className='mx-5 view-form'>
          <Col lg={12} sm={12} xs={12} >
            <Row className="py-2 ibs-edit-form">
              <Col lg={6} sm={6} xs={6}>
                <label>Account Name</label>
                <span className="text-capitalize">
                  {contactRecord?.accountname ? contactRecord?.accountname : <>&nbsp;</>}
                </span>
              </Col>

              <Col lg={6} sm={6} xs={6}>
                <label>Name</label>
                <span className="text-capitalize">
                  {contactRecord.salutation ? contactRecord.salutation + " " : <>&nbsp;</>}
                  {contactRecord.firstname ? contactRecord.firstname + " " : <>&nbsp;</>}
                  {contactRecord.lastname ? contactRecord.lastname : <>&nbsp;</>}
                </span>
              </Col>

              <Col lg={6} sm={6} xs={6}>
                <label>Title</label>
                <span className="text-capitalize">
                  {contactRecord?.title ? contactRecord?.title : <>&nbsp;</>}
                </span>
              </Col>

              <Col lg={6} sm={6} xs={6}>
                <label>Email</label>
                <span>{contactRecord.email ? contactRecord.email : <>&nbsp;</>}</span>
              </Col>

              <Col lg={6} sm={6} xs={6}>
                <label>Phone</label>
                <span>{contactRecord.phone ? contactRecord.phone : <>&nbsp;</>}</span>
              </Col>

              <Col lg={6} sm={6} xs={6}>
                <label>Whatsapp Number</label>
                <span>{contactRecord.whatsapp_number ? contactRecord.whatsapp_number : <>&nbsp;</>}</span>
              </Col>

              <Col lg={6} sm={6} xs={6}>
                <label>City</label>
                <span>{contactRecord.city ? contactRecord.city : <>&nbsp;</>}</span>
              </Col>

              <Col lg={6} sm={6} xs={6}>
                <label>State</label>
                <span>{contactRecord.state ? contactRecord.state : <>&nbsp;</>}</span>
              </Col>

              <Col lg={6} sm={6} xs={6}>
                <label>Country</label>
                <span>{contactRecord.country ? contactRecord.country : <>&nbsp;</>}</span>
              </Col>

              <Col lg={6} sm={6} xs={6}>
                <label>Street</label>
                <span>{contactRecord.street ? contactRecord.street : <>&nbsp;</>}</span>
              </Col>

              <Col lg={6} sm={6} xs={6} className="mb-3">
                <label>Pincode</label>
                <span>{contactRecord.pincode ? contactRecord.pincode : <>&nbsp;</>}</span>
              </Col>

            </Row>
          </Col>
        </Row >
      </Container >



      {/* {contact && (
        <Container className='mt-5'>
          <Row className="mx-5 view-form">
            <Col lg={12} xs={12} sm={12}>

              <Row className="view-form-header align-items-center">
                <Col lg={8} xs={6} sm={6}>
                  <h5>Contact Record</h5>
                  <p className="text-capitalize"> {contact.salutation} {contact.firstname} {contact.lastname}</p>
                </Col>

                <Col lg={4} xs={6} sm={6} className="d-flex justify-content-end">
                  <Button className="btn-sm mx-2" onClick={() => editContact(true)} >
                    <i className="fa-regular fa-pen-to-square"></i>
                  </Button>
                  <Button className="btn-sm" variant="danger" onClick={() => setModalShow(true)} >
                    Delete
                  </Button>
                </Col>
              </Row>

              <Row className="py-1 ibs-edit-form">
                <Col lg={12} xs={12} sm={12}>
                  <Row className="mx-2 mb-3">
                    <Col lg={6} xs={6} sm={6}>
                      <label>Account Name</label>
                      <Link to={contact.accountid ? "/accounts/" + contact.accountid : "#"} state={{ id: contact.accountid }}>
                        <span>{contact.accountname ? contact.accountname : <>&nbsp;</>}</span>
                      </Link>
                    </Col>
                    <Col lg={6}>
                      <label>Name</label>
                      <span className="text-capitalize">
                        {contact.salutation ? contact.salutation + " " : <>&nbsp;</>}
                        {contact.firstname ? contact.firstname + " " : <>&nbsp;</>}
                        {contact.lastname ? contact.lastname : <>&nbsp;</>}
                      </span>
                    </Col>
                    <Col lg={6}>
                      <label>Email</label>
                      <span>{contact.email ? contact.email : <>&nbsp;</>}</span>
                    </Col>
                    <Col lg={6}>
                      <label>Phone</label>
                      <span>{contact.phone ? contact.phone : <>&nbsp;</>}</span>
                    </Col>
                    <Col lg={6}>
                      <label>Whatsapp Number</label>
                      <span>{contact.whatsapp_number ? contact.whatsapp_number : <>&nbsp;</>}</span>
                    </Col>
                    <Col lg={6}>
                      <label>Title</label>
                      <span>{contact.title ? contact.title : <>&nbsp;</>}</span>
                    </Col>
                    <Col lg={6}>
                      <label>City</label>
                      <span>{contact.city ? contact.city : <>&nbsp;</>}</span>
                    </Col>
                    <Col lg={6}>
                      <label>State</label>
                      <span>{contact.state ? contact.state : <>&nbsp;</>}</span>
                    </Col>
                    <Col lg={6}>
                      <label>Pincode</label>
                      <span>{contact.pincode ? contact.pincode : <>&nbsp;</>}</span>
                    </Col>
                    <Col lg={6}>
                      <label>Country</label>
                      <span>{contact.country ? contact.country : <>&nbsp;</>}</span>
                    </Col>
                    <Col lg={6}>
                      <label>Street</label>
                      <span>{contact.street ? contact.street : <>&nbsp;</>}</span>
                    </Col>
                  </Row>
                </Col>

              </Row>
            </Col>
          </Row>
        </Container>


      )} */}


    </>
  );
};
export default ContactView;
