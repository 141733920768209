/**
 * @author      Abdul Pathan
 * @date        Aug, 2024
 * @copyright   www.ibirdsservices.com
 */

import React, { useEffect, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap';
// import { useLocation } from "react-router-dom";
import { toast } from 'react-toastify'; // npm i react-toastify --force
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment-timezone';
import WhatsAppAPI from '../../api/WhatsAppAPI';

const CampaignEdit = (props) => {
    // const location = useLocation();
    console.log('props?.rowData', props?.rowData)
    // const [campaignData, setCampaignData] = useState(props?.rowData ? props?.rowData : { campaign_id: '', campaign_name: '', campaign_type: '', template_id: '', templatename: '', start_date: '' });
    const [campaignData, setCampaignData] = useState(props?.rowData || {
        campaign_id: '',
        campaign_name: '',
        campaign_type: '',
        template_id: '',
        template_name: '',
        start_date: ''
    });
    const [allTemplateData, setAllTemplateData] = useState([]);

    useEffect(() => {
        fetchAllTemplate();
    }, []);

    // Fetch All template
    const fetchAllTemplate = async () => {
        try {
            const result = await WhatsAppAPI.getAllTemplateData();

            const transformedData = result?.data.map(row => {
                const header = row.components.find(component => component.type === 'HEADER') || {};
                const body = row.components.find(component => component.type === 'BODY') || {};
                const footer = row.components.find(component => component.type === 'FOOTER') || {};

                return {
                    template_id: row.id,
                    template_name: row.name,
                    templatename: row.name.replace(/_/g, ' '),
                    category: row.category,
                    status: row.status,
                    language: row.language,
                    header: header.format === 'TEXT' ? 'text' : '',
                    header_text: header.format === 'TEXT' ? header.text : '',
                    header_image_url: header.format === 'IMAGE' ? (header.example?.header_handle?.[0] || '') : '',
                    message: body.text || '',
                    footer: footer.text || '',
                    add_security_recommendation: body.add_security_recommendation || false,
                    code_expiration_minutes: footer.code_expiration_minutes || null
                };
            });

            console.log('transformedData', transformedData);
            const approvedTemplates = transformedData.filter(template => template.status === 'APPROVED');
            console.log('Approved Templates', approvedTemplates);
            setAllTemplateData(approvedTemplates);

        } catch (error) {
            console.error('Failed to fetch templates:', error);
        }
    };


    const formatDateForInput = (date) => {
        return date ? moment(date).format('YYYY-MM-DDTHH:mm') : '';
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCampaignData(prevData => ({
            ...prevData,
            [name]: name === 'start_date' ? (value ? moment(value).tz('Asia/Kolkata').toDate() : '') : value
        }));

        // if (name === 'start_date') {
        //     setCampaignData({
        //         ...campaignData, [name]: value ? moment(value).tz('Asia/Kolkata').toDate() : ''
        //     });
        // }
        // else {
        //     setCampaignData({ ...campaignData, [name]: value });
        // }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formattedCampaignData = {
            ...campaignData,
            start_date: campaignData.start_date ? moment(campaignData.start_date).tz('Asia/Kolkata').format('YYYY-MM-DDTHH:mm:ss.SSSZ') : ''
        };

        console.log('Saving data...', formattedCampaignData);

        if (formattedCampaignData?.campaign_id && formattedCampaignData.campaign_name.trim() && formattedCampaignData.start_date && formattedCampaignData.template_name) {
            try {
                const obj = {
                    id: formattedCampaignData.campaign_id,
                    name: formattedCampaignData.campaign_name.trim(),
                    type: formattedCampaignData?.campaign_type ? formattedCampaignData.campaign_type : '',
                    template_name: formattedCampaignData.template_name,
                    start_date: formattedCampaignData.start_date
                }
                const result = await WhatsAppAPI.updateCampaignRecord(obj);
                console.log('## Result update campaign records', result);
                if (result.success) {
                    props.onRefreshData();
                } else {
                    toast.error(result.message)
                }
            } catch (error) {
                console.error('Error updating campaign records', error);
            }
        } else {
            toast.error('Requires field missing.')
        }
    }

    const isFormValid = Boolean(campaignData.campaign_name.trim()) && Boolean(campaignData.start_date) && Boolean(campaignData.template_name);

    return (
        <>
            <Modal show={props.show} animation={false} size='lg' centered >
                <Modal.Header closeButton onClick={props.onHide}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Record
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form noValidate >
                        <Row className='p-2 mb-3'>
                            <Col lg={6} sm={12} xs={12}>
                                <Form.Group className="mb-3" controlId="formCampaignName">
                                    <Form.Label>Campaign Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        required
                                        name="campaign_name"
                                        value={campaignData.campaign_name}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>

                            <Col lg={6} sm={12} xs={12}>
                                <Form.Group className='mb-3'>
                                    <Form.Label htmlFor="formType">Type</Form.Label>
                                    <Form.Select
                                        aria-label="select type"
                                        name="campaign_type"
                                        value={campaignData?.campaign_type}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select type</option>
                                        <option value="Advertisement">Advertisement</option>
                                        <option value="Banner Ads">Banner Ads</option>
                                        <option value="Conference">Conference</option>
                                        <option value="Direct Mail">Direct Mail</option>
                                        <option value="Email">Email</option>
                                        <option value="Partners">Partners</option>
                                        <option value="Public Relations">Public Relations</option>
                                        <option value="Referral Program">Referral Program</option>
                                        <option value="Telemarketing">Telemarketing</option>
                                        <option value="Trade Show">Trade Show</option>
                                        <option value="Web">Web</option>
                                        <option value="Webinar">Webinar</option>
                                        <option value="Other">Other</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col lg={6} sm={12} xs={12}>
                                <Form.Group className='mb-3'>
                                    <Form.Label htmlFor="formType">Template Name</Form.Label>
                                    <Form.Select
                                        aria-label="select name"
                                        name="template_name"
                                        required
                                        onChange={handleChange}
                                        value={campaignData?.template_name}
                                    >
                                        <option value="">Select Template Name</option>
                                        {allTemplateData?.map((template) => (
                                            <option key={template.template_id} value={template.template_name}>
                                                {template.templatename}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col lg={6} sm={12} xs={12}>
                                <Form.Group className="mb-3" controlId="formStartDate">
                                    <Form.Label>Start Date & Time</Form.Label>
                                    <Form.Control
                                        required
                                        type="datetime-local"
                                        name="start_date"
                                        value={formatDateForInput(campaignData?.start_date)}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>

                        </Row>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <button className='btn btn-light' onClick={props.onHide}>Close</button>
                    <button className='btn btn-outline-primary' onClick={handleSubmit} disabled={!isFormValid}>Save</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CampaignEdit
