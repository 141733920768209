import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import PieChart from './charts/PieChart';
import DoughnutChart from './charts/DoughnutChart';

const Home = () => {

  return (
    <>
      <Container className='mt-3'>
        <Row>
          <Col lg={6} sm={12} xs={12}>
            <Row className="ms-1">
              <Card className="p-3 shadow-sm d-flex justify-content-between align-items-center">
                <Card.Title className="text-center">WhatsApp Templates</Card.Title>
                <Col lg={12} sm={12} xs={12}  >
                  <div style={{ height: "400px" }}>
                    <DoughnutChart />
                  </div>
                </Col>
              </Card>
            </Row>
          </Col>

          <Col lg={6} sm={12} xs={12}>
            <Row className="ms-1">
              <Card className="p-3 shadow-sm d-flex justify-content-between align-items-center">
                <Card.Title className="text-center">Campaign</Card.Title>
                <Col lg={12} sm={12} xs={12} >
                  <div style={{ height: "400px" }}>
                    <PieChart />
                  </div>
                </Col>
              </Card>
            </Row>
          </Col>

        </Row>
      </Container>

    </>
  )
}

export default Home
