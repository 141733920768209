/**
 * @author      Abdul Pathan
 * @date        Aug, 2024
 * @copyright   www.ibirdsservices.com
 */

import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap'
import WhatsAppAPI from '../../api/WhatsAppAPI';
import { ToastContainer, toast } from 'react-toastify'; // npm i react-toastify --force
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
// const moment = require('moment-timezone');

const CampaignAdd = () => {
    const navigate = useNavigate();
    const [allTemplateData, setAllTemplateData] = useState([]);
    const [selectedTemplateName, setSelectedTemplateName] = useState('');
    const [campaignRecord, setCampaignRecord] = useState({ name: '', description: '', start_date: '', type: '' });
    const [selectedFile, setSelectedFile] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [isSpinner, setIsSpinner] = useState(false);
    const fileInputRef = useRef(null);


    useEffect(() => {
        fetchAllTemplate();
    }, []);

    // Fetch All template
    const fetchAllTemplate = async () => {
        try {
            const result = await WhatsAppAPI.getAllTemplateData();
            console.log('##fetch All Template', result.data);

            if (result.error) {
                // toast.error(result?.error?.message);
                setAllTemplateData([])
                setIsSpinner(true);
            } else {
                const transformedData = result?.data.map(row => {
                    const header = row.components.find(component => component.type === 'HEADER') || {};
                    const body = row.components.find(component => component.type === 'BODY') || {};
                    const footer = row.components.find(component => component.type === 'FOOTER') || {};

                    return {
                        template_id: row.id,
                        template_name: row.name,
                        templatename: row.name.replace(/_/g, ' '),
                        category: row.category,
                        status: row.status,
                        language: row.language,
                        header_text: header.format === 'TEXT' ? header.text : '',
                        header_image_url: header.format === 'IMAGE' ? (header.example?.header_handle?.[0] || '') : '',
                        message: body.text || '',
                        footer: footer.text || '',
                        add_security_recommendation: body.add_security_recommendation || false,
                        code_expiration_minutes: footer.code_expiration_minutes || null
                    };
                });

                console.log('transformedData', transformedData);
                const approvedTemplates = transformedData.filter(template => template.status === 'APPROVED');
                console.log('Approved Templates', approvedTemplates);
                setAllTemplateData(approvedTemplates);
                setIsSpinner(true);
            }
        } catch (error) {
            toast.error('Failed to fetch templates.');
        }
    };

    // handle template change
    const handleChange = async (event) => {
        const selectedName = event.target.value;
        console.log('selected Name ', selectedName)

        const template = allTemplateData.find(t => t.template_name === selectedName);
        console.log('template: ', template)

        if (template) {
            setSelectedTemplateName(template?.template_name)
        } else {
            setSelectedTemplateName('')
        }
    }

    const handleChangeName = (event) => {
        const { name, value } = event.target;
        if (name === 'start_date') {
            setCampaignRecord({
                ...campaignRecord, [name]: value ? moment(value).tz('Asia/Kolkata').toDate() : ''
            });
        }
        setCampaignRecord({ ...campaignRecord, [name]: value });
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        console.log('filefile', file)

        if (file) {
            const validExtensions = ['xls', 'xlsx', 'csv'];
            const fileExtension = file.name.split('.').pop().toLowerCase();
            console.log('fileExtension', fileExtension)

            if (!validExtensions.includes(fileExtension)) {
                setErrorMessage('Only .csv, .xls and .xlsx files are allowed.');
                setSelectedFile(null); // Clear the file input
                event.target.value = ''; // Reset file input value
                return;
            }

            setSelectedFile([file]);
            setErrorMessage('');
            console.log('##handle file Change', file);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!campaignRecord.name) {
            toast.error("Campaign name required.");
            return;
        }

        if (!selectedTemplateName) {
            toast.error("Template name required.");
            return;
        }

        if (!selectedFile) {
            toast.error("Please select a file.");
            return;
        }

        const desc = campaignRecord?.description ? campaignRecord.description : '';

        var formData = new FormData();
        for (let i = 0; i < selectedFile.length; i++) {
            formData.append(`selectedFile${i}`, selectedFile[i]);
            formData.append(`description`, desc);
        }

        const campiagnData = {
            name: campaignRecord.name,
            type: campaignRecord.type || 'Web',
            status: 'Pending',
            template_name: selectedTemplateName, //selectedTemplate,
            startDate: campaignRecord.start_date
                ? moment(campaignRecord.start_date).tz('Asia/Kolkata').format('YYYY-MM-DDTHH:mm:ss.SSSZ')
                : moment.tz(new Date(), "Asia/Kolkata").toDate(),
        };

        try {

            const campaignResult = await WhatsAppAPI.insertCampaignRecords(campiagnData);
            console.log('Insert Campaign Record', campaignResult);

            if (campaignResult.success) {
                const cpId = campaignResult.record.id;
                console.log('campaign id: ', cpId)

                const result = await WhatsAppAPI.createCampaignFile(cpId, formData);
                console.log('result', result)
                if (result.success) {
                    toast.success('Record Inserted Successfully!');
                    navigate('/campaign')
                }
                setIsSpinner(false)
            }
        } catch (error) {
            toast.error("Failed to send message.");
        } finally {
            setIsSending(false);
            setIsSpinner(false);
        }
    };

    // clear
    const handleClear = () => {
        setSelectedTemplateName('');
        setCampaignRecord({ name: '', description: '', start_date: '', type: '' });
        setSelectedFile(null);
        setIsSending(false);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const formatDateForInput = (date) => {
        return date ? moment(date).format('YYYY-MM-DDTHH:mm') : '';
    };

    const isFormValid = Boolean(selectedTemplateName) && Boolean(campaignRecord.name) && Boolean(selectedFile);


    const handleBack = () => {
        navigate("/campaign");
    }

    return (
        <>
            <Container className='mt-5'>
                <Row className='mx-5 text-center'>
                    <Col lg={12} xs={12} sm={12}>
                        <div className=' text-center p-2' style={{ height: '40px', backgroundColor: '#ffffff', borderRadius: '5px' }}>
                            <span className='fw-semibold p-1' style={{ color: '#605C68', fontSize: 'large' }}>
                                Create New Campaign
                            </span>
                        </div>
                    </Col>
                </Row>
            </Container>


            {isSpinner ? <>

                <Container className='mt-2 mb-5'>
                    <Row className='mx-5'>
                        <Col lg={12} sm={12} xs={12} className="mb-2">
                            <Card className='h-100' style={{ border: "none" }}>
                                <Card.Body>
                                    {/* <Form onSubmit={Submit} > */}
                                    <Row className='mb-3'>
                                        <Col lg={6} sm={12} xs={12}>
                                            <Form.Group className="mb-3 mx-2" controlId="formCampaignName">
                                                <Form.Label>Campaign Name</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    name="name"
                                                    placeholder='Enter campain name'
                                                    value={campaignRecord?.name}
                                                    onChange={handleChangeName}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col lg={6} sm={12} xs={12}>
                                            <Form.Group className='mb-3 mx-2'>
                                                <Form.Label className="form-view-label" htmlFor="formBasicFirstName">
                                                    Template Name
                                                </Form.Label>
                                                <Form.Select
                                                    required
                                                    aria-label="select name"
                                                    name="templateName"
                                                    onChange={handleChange}
                                                    placeholder='Select Template Name'
                                                    value={selectedTemplateName}
                                                >
                                                    <option value="">Select Template Name</option>
                                                    {allTemplateData?.map((template) => (
                                                        <option key={template.template_id} value={template.template_name}>
                                                            {template.templatename}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className='mb-3'>

                                        <Col lg={6} sm={12} xs={12}>
                                            <Form.Group className="mb-3 mx-2" controlId="formStartDate">
                                                <Form.Label>Start Date & Time</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="datetime-local"
                                                    name="start_date"
                                                    value={formatDateForInput(campaignRecord?.start_date)}
                                                    onChange={handleChangeName}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col lg={6} sm={12} xs={12}>
                                            <Form.Group className='mb-3 mx-2'>
                                                <Form.Label htmlFor="formType">Type</Form.Label>
                                                <Form.Select
                                                    aria-label="select type"
                                                    name="type"
                                                    value={campaignRecord?.type}
                                                    onChange={handleChangeName}
                                                >
                                                    <option value="">Select type</option>
                                                    <option value="Advertisement">Advertisement</option>
                                                    <option value="Banner Ads">Banner Ads</option>
                                                    <option value="Conference">Conference</option>
                                                    <option value="Direct Mail">Direct Mail</option>
                                                    <option value="Email">Email</option>
                                                    <option value="Partners">Partners</option>
                                                    <option value="Public Relations">Public Relations</option>
                                                    <option value="Web">Web</option>
                                                    <option value="Other">Other</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>

                                    </Row>

                                    <Row className='mb-3 mt-3'>

                                        <Col lg={6} sm={12} xs={12}>
                                            <Form.Group controlId="formFile">
                                                <Form.Label >File Upload</Form.Label>
                                                <Form.Control
                                                    type="file"
                                                    ref={fileInputRef}
                                                    accept=".csv" // Only allow .xls and .xlsx files
                                                    onChange={handleFileChange}
                                                />
                                                {errorMessage && <Form.Text className="text-danger">{errorMessage}</Form.Text>}
                                            </Form.Group>
                                        </Col>

                                        <Col lg={6} sm={12} xs={12}>
                                            <Form.Group className="mb-3 mx-2" controlId="formCampaignName mb-5">
                                                <Form.Label>Description</Form.Label>
                                                <Form.Control
                                                    // as="textarea"
                                                    type="text"
                                                    name="description"
                                                    placeholder='type description here...'
                                                    value={campaignRecord?.description}
                                                    onChange={handleChangeName}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mt-2'>
                                        <Col lg={12} sm={12} xs={12} className=" mt-4">
                                            <hr></hr>
                                        </Col>
                                    </Row>

                                    <Row className='g-0 mb-2'>
                                        <Col lg={12} sm={12} xs={12} className="text-end mt-2">
                                            <Button className='mx-2' variant="light" onClick={handleBack} disabled={isSending}>
                                                Back
                                            </Button>
                                            <Button className='mx-2' variant="light" onClick={handleClear} disabled={isSending}>
                                                Clear
                                            </Button>
                                            <Button variant="outline-secondary" disabled={!isFormValid || isSending} onClick={handleSubmit} type="button">
                                                {isSending ? 'Submitting...' : 'Submit'}
                                            </Button>
                                        </Col>
                                    </Row>
                                    {/* </Form> */}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row >
                </Container >

            </>
                :

                <div className="sk-cube-grid">
                    <div className="sk-cube sk-cube1"></div>
                    <div className="sk-cube sk-cube2"></div>
                    <div className="sk-cube sk-cube3"></div>
                    <div className="sk-cube sk-cube4"></div>
                    <div className="sk-cube sk-cube5"></div>
                    <div className="sk-cube sk-cube6"></div>
                    <div className="sk-cube sk-cube7"></div>
                    <div className="sk-cube sk-cube8"></div>
                    <div className="sk-cube sk-cube9"></div>
                </div>

            }

            <ToastContainer />
        </>
    )
}

export default CampaignAdd;
