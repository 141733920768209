import React, { useEffect, useState } from "react";
import { Alert, Badge, Button, Image, Modal, NavDropdown } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from "react-router-dom";
import authApi from "../../api/authApi";
import jwt_decode from "jwt-decode";
import { isMobile, MobileView, BrowserView } from 'react-device-detect';
import { MenuItem } from "react-bootstrap-typeahead";
import Notification from "../common/Notification";
import WhatsAppAPI from "../../api/WhatsAppAPI";
import helper from "../common/helper";

const Header = ({ socket, notifications }) => {
  const [refreshNotification, setRefreshNotification] = useState(new Date());
  const [showBellAlert, setShowBellAlert] = useState(false);
  const [unreadMsgCount, setUnreadMsgCount] = useState(0);
  const [messages, setMessages] = useState(0);
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    messageList();
    if (!localStorage.getItem("token")) navigate("/login");
    if (localStorage.getItem("notifications") && JSON.parse(localStorage.getItem("notifications")).length > 0) {
      navigate("/maintanance");
    }
    try {
      setUserInfo(jwt_decode(localStorage.getItem('token')));
    } catch (error) {
      console.log(error)
    }
    socket?.on("greetings", (data) => {
      // console.log("connected on client", data);
      messageList();
    })
  }, [socket]);

  const messageList = async () => {
    let tasks = '';
    if (tasks && tasks?.length > 0) {
      setUnreadMsgCount(tasks.length);
      //added by moin : 13-07-2023
      helper.generateDescriptionHTML(tasks);
      setMessages(tasks)
      setShowBellAlert(true);
    } else {
      setMessages([])
      setUnreadMsgCount(0);
      setShowBellAlert(true);
    }
  };

  const logout = () => {
    authApi.logout();
    navigate("/login");
  };

  const toggleSidebar = () => {

    document.querySelector("#sidebar").classList.toggle("hide");
    document.querySelector("#sidebar").classList.toggle("show");


  };

  const [showNotification, setShowNotification] = useState(false);

  const closeNotification = (msgId) => {
    updateStatusUnreadMessage(msgId);
    setShowNotification(false);
  };
  const updateStatusUnreadMessage = async (msgId) => {
    let res = await WhatsAppAPI.updateStatusUnreadMessage(msgId);
    messageList();
  };

  return (
    <>
      <Navbar className="header px-2" bg="" expand="lg" variant="" style={{ marginBottom: isMobile ? "1rem" : "0" }}>
        <button
          type="button"
          id="sidebarCollapse"
          className="btn btn-info"
          onClick={toggleSidebar}
        >
          <i className="fas fa-align-left"></i>
        </button>
        <Navbar.Brand href="#home"></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#" className="p-0" style={{ fontSize: ".9rem" }}>
              <img src={localStorage.getItem("myimage")} alt="" className="rounded-circle" style={{ height: "30px", width: "30px" }} />
              <Badge style={{ fontSize: ".9rem" }} bg="light" text="dark">{userInfo.username} </Badge>
              <Badge bg="success" style={{ fontSize: ".9rem" }}>{userInfo.userrole} </Badge> </Nav.Link>
            <Nav.Link href="#" className="d-flex p-0" style={{ alignItems: "center" }}><span className="mx-2" style={{ fontSize: ".9rem" }}>Company</span> <Badge style={{ fontSize: ".9rem" }} bg="secondary">{userInfo.companyname} </Badge> </Nav.Link>
          </Nav>
          <Nav className="ml-auto d-flex align-items-center ">
            {/* <div className="icon-wrapper">
              <i className="fa-solid fa-bell cic mx-4" onClick={() => { messageList(); setShowNotification(!showNotification) }} style={{ cursor: "pointer", fontSize: "1.5rem" }}></i>
              {showBellAlert && <span className="notify-number">{unreadMsgCount}</span>}
            </div> */}

            {showNotification &&
              <Notification notifications={messages} closeNotification={closeNotification} refreshNotification={refreshNotification} />}
            {localStorage.getItem("token") ? (
              <Button variant="btn btn-primary" onClick={logout} title="Logout">
                <i className="fa-solid fa-right-from-bracket"></i>
              </Button>
            ) : (
              <></>
            )}

          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};
export default Header;
